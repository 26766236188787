import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import JobSupportBanner from '../../../assets/images/Job-support-banner.png';
import colors from '../../../utility/styles';

const Container = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const BrowseCourses = ({ isMobile }) => {
    return (
        <Container>
                { isMobile ? 
                <>
                    <img src={JobSupportBanner} alt='image' style={{height: 'auto', width: '60%'}} />
                    <Box sx={{marginTop: '30px', width: '100%'}}>
                        <H2>
                            Find & Hire Top 3% of Expert <br />
                            with EduBriq Job Support!
                        </H2>
                        <P2 style={{marginTop: '30px', color: '#5f5f5f', textAlign: 'justify'}}>
                            The Job Support Portal is a comprehensive platform designed to assist job seekers
                            with resources, guidance and opportunities to enhance their career prospects.
                        </P2>
                        
                        <Button variant='contained' sx={{marginTop: '50px'}}>
                            <P2>
                                Browse Courses!
                            </P2>
                        </Button>
                    </Box>
                </>
                :
                <>
                    <Box sx={{width: '50%'}}>
                        <H2>
                            Find & Hire Top 3% of Expert <br />
                            with EduBriq Job Support!
                        </H2>
                        <P2 style={{marginTop: '30px', color: '#5f5f5f'}}>
                            The Job Support Portal is a comprehensive platform designed to assist job seekers
                            with resources, guidance and opportunities to enhance their career prospects.
                        </P2>

                        <Button variant='contained' sx={{marginTop: '50px'}}>
                            <P2 sx={{fontWeight: 'bold'}}>
                                Browse Courses!
                            </P2>
                        </Button>
                    </Box>
                    <img src={JobSupportBanner} alt='image' style={{width: '30%'}} />
                </>
                }
        </Container>
    )
}

export default BrowseCourses;