import React from 'react';
import { Box, styled, width } from '@mui/system';
import COLORS from '../utility/styles';
import Typography from '@mui/material/Typography';
import { MdEmail } from 'react-icons/md';
import { MdPhoneAndroid } from 'react-icons/md';
import { Grid } from '@mui/material';
import { Hyperlink, NavigationLink } from '../components/Hyperlink';
import { TiSocialYoutube } from 'react-icons/ti';
import { TfiFacebook } from "react-icons/tfi";
import { FaLinkedinIn } from 'react-icons/fa';
import EdubriqLogo from '../images/Logo.png';

const FooterContainer = styled('div')(
    ({ theme }) => `
    height: auto;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${COLORS.footerColor};
    `
)

const ContactBox = styled(Box)(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-top: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(1)};
    `
)

const ContactIcon = styled('div')(
    ({ theme }) => `
    font-size: 1.8rem;
    color: ${COLORS.primaryColor};
    margin-right: ${theme.spacing(0.5)}
    `
)

const ContactText = styled(Typography)(
    ({ theme }) => `
    color: ${COLORS.greyColor};
    `
)

const Logo = styled('img')(
    ({ theme }) => `
    cursor: pointer;
    height: ${theme.spacing(12)};
    margin: ${theme.spacing(0.5)};
    `
)

const FooterSectionHeader = styled(Typography)(
    ({ theme }) => `
    margin-top: ${theme.spacing(1.5)};
    margin-bottom: ${theme.spacing(4)};
    `
)

const SocialIconContainer = styled('div')(
    ({ theme, color }) => `
    background-color: ${color};
    border-radius: 50%;
    padding: ${theme.spacing(.6)};
    margin: ${theme.spacing(1.5)};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    `
)

const Footer = () => {
    let getYear = () => {
        let currentYear = new Date().getFullYear();
        return currentYear;
    };
    return (
        <FooterContainer
            id='footer'
        >
            <Box
                sx={{
                    display: 'flex',
                    width: 1,
                    mt: 5
                }}
            >
                <Grid
                    sx={{
                        paddingLeft: 10,
                        paddingTop: 10,
                        paddingRight: 8,
                        paddingBottom: 10
                    }}
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={2}>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'start',
                            borderColor: { xl: 'grey.300', lg: 'grey.300', md: 'grey.300' }
                        }}
                    >
                        <ContactBox>
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <NavigationLink to='/'><Logo src={EdubriqLogo} /></NavigationLink>
                                <ContactText variant='body1'>Suite 103, 661 George Street</ContactText>
                                <ContactText variant='body1'>Haymarket NSW 2000, Australia</ContactText>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ContactIcon><MdPhoneAndroid /></ContactIcon><Typography variant='body1'>+61 400 810 625</Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ContactIcon><MdEmail /></ContactIcon><Typography variant='body1'>info@edubriq.com</Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                            >

                                <Hyperlink href='https://www.facebook.com/profile.php?id=100083803621943' target="_blank"><SocialIconContainer color={COLORS.facebookColor}><TfiFacebook style={{color: "white"}}   /></SocialIconContainer></Hyperlink>
                                {/* <SocialIconContainer color={COLORS.linkedInColor}><TiSocialLinkedin /></SocialIconContainer> */}
                                <Hyperlink href='https://www.linkedin.com/company/nextgen-myhealth-vcp/' target="_blank" ><SocialIconContainer color={COLORS.linkedInColor}><FaLinkedinIn style={{color: "white"}}/></SocialIconContainer></Hyperlink>
                                <Hyperlink href='https://www.youtube.com/channel/UCSDFJqW2y9UYs6IMWGK2i9w' target="_blank"><SocialIconContainer color={COLORS.youtubeColor}><TiSocialYoutube style={{color: "white"}}/></SocialIconContainer></Hyperlink>

                            </Box>

                        </ContactBox>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: { xl: 'center', lg: 'center', md: 'center', sm: 'center', xs: 'start' },
                            alignItems: 'start',
                            borderColor: { xl: 'grey.300', lg: 'grey.300', md: 'grey.300' }
                        }}
                    >
                        <ContactBox>
                            <FooterSectionHeader variant='h5'>Company</FooterSectionHeader>
                            <NavigationLink to='/contact'><ContactText variant='body1'>Contact Us</ContactText></NavigationLink>
                            <NavigationLink to=''><ContactText variant='body1'>Corporate Brief</ContactText></NavigationLink>
                            <NavigationLink to=''><ContactText variant='body1'>Partners</ContactText></NavigationLink>
                        </ContactBox>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={6} xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: { xl: 'center', lg: 'center', md: 'center', sm: 'center', xs: 'start' },
                            alignItems: 'start',
                            borderColor: { xl: 'grey.300', lg: 'grey.300', md: 'grey.300' }
                        }}
                    >
                        <ContactBox>
                            <FooterSectionHeader variant='h5'>Services & Products</FooterSectionHeader>
                            <NavigationLink to='/counselling'><ContactText variant='body1'>Student Counseling</ContactText></NavigationLink>
                            <NavigationLink to='/tutor'><ContactText variant='body1'>Edubriq Tutor</ContactText></NavigationLink>
                            <NavigationLink to='/academy'><ContactText variant='body1'>Edubriq Academy</ContactText></NavigationLink>
                            <NavigationLink to='/jobsupport'><ContactText variant='body1'>Edubriq Job Support</ContactText></NavigationLink>
                            <NavigationLink to=''><ContactText variant='body1'>Edubriq Solutions</ContactText></NavigationLink>
                            <NavigationLink to=''><ContactText variant='body1'>Others</ContactText></NavigationLink>
                        </ContactBox>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12}
                        sx={{
                            display: 'flex',
                            justifyContent: { xl: 'center', lg: 'center', md: 'center', sm: 'center', xs: 'start' },
                            alignItems: 'start',
                        }}
                    >
                        <ContactBox>
                            <FooterSectionHeader variant='h5'>Customer Care</FooterSectionHeader>
                            {/* <AppIcon src={GooglePlay} />
                                <AppIcon src={AppleStore} /> */}
                            <NavigationLink to=''><ContactText variant='body1'>Our Support</ContactText></NavigationLink>
                            <NavigationLink to=''><ContactText variant='body1'>Talk To An Agent</ContactText></NavigationLink>
                            <NavigationLink to=''><ContactText variant='body1'>Chat With An Agent</ContactText></NavigationLink>
                            <NavigationLink to=''><ContactText variant='body1'>Visit Our Office</ContactText></NavigationLink>

                        </ContactBox>
                    </Grid>
                </Grid>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    // justifyContent: 'center',
                    flexDirection: 'column',
                    width: '100%',
                    background: '#022598',
                    p: 2
                }}
            >
                <Typography
                    variant='body2'
                    align="center"
                >
                    <Box
                        sx={{
                            color: COLORS.lightGrey
                        }}
                    >
                        <Hyperlink
                            href='/terms-and-conditions'
                            color={COLORS.lightGrey}
                        >
                            Terms and Conditions
                        </Hyperlink>|
                        <Hyperlink
                            href='/privacy-policy'
                            color={COLORS.lightGrey}
                        >
                            Privacy Policy
                        </Hyperlink>|
                        <Hyperlink
                            href='/refund-policy'
                            color={COLORS.lightGrey}
                        >
                            Refund Policy
                        </Hyperlink>
                    </Box>
                </Typography>
                <Typography variant='body2' sx={{ color: COLORS.lightGrey }} align="center">Copyright © {getYear()} NextGen DigiTech Ltd. All rights reserved.</Typography>
            </Box>
        </FooterContainer>
    )
}

export default Footer