import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import Banner from './Banner';
import Services from './Services';
import AboutUs from './AboutUs';
import Specialties from './Specialties';
import Review from './Review';

const Home = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, []);

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Banner isMobile={isMobile} />
            <Services isMobile={isMobile} />
            <AboutUs isMobile={isMobile} />
            <Specialties isMobile={isMobile} />
            <Review isMobile={isMobile} />
        </div>
    )
}

export default Home;