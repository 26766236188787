import { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import ProfileImage1 from '../../../assets/images/Job-support-profile-image1.png';
import ProfileImage2 from '../../../assets/images/Job-support-profile-image2.png';
import Colors from '../../../utility/styles';
import ProfileCard from './ProfileCard.js';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const ProfileContainer = styled(Box)(({theme})=>({
    display: 'flex',
    width: '100%',
    marginTop: '30px',

    [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        justifyContent: 'center'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        justifyContent: 'space-evenly'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        justifyContent: 'space-evenly'
    },
    [theme.breakpoints.up('xl')]: {
        justifyContent: 'space-evenly'
    }
}))

const LeftControl = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    width: '50px',
    borderRadius: '50%',

    ':hover': {
        backgroundColor: `${Colors.lightBlue}`
    },
    ':active': {
        backgroundColor: `${Colors.lightBlue}`
    }
})

const RightControl = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    width: '50px',
    borderRadius: '50%',

    ':hover': {
        backgroundColor: `${Colors.lightBlue}`
    },
    ':active': {
        backgroundColor: `${Colors.lightBlue}`
    }
})

const ControlBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-around',
    width: '200px',
    marginTop: '30px'
})

const LeftIcon = styled(SlArrowLeft)({
    fontSize: '20px',
})

const RightIcon = styled(SlArrowRight)({
    fontSize: '20px',
})

const profileList = [
    {
        profileImage: ProfileImage1,
        name: 'Sania Hasan',
        title: 'Web Developer',
        stars: 5,
        experience: 3,
        salary: '$80K'
    },
    {
        profileImage: ProfileImage2,
        name: 'Amit Ahmed',
        title: 'Graphics Designer',
        stars: 5,
        experience: 8,
        salary: '$100K'
    },
    {
        profileImage: ProfileImage1,
        name: 'Sania Hasan',
        title: 'Web Developer',
        stars: 5,
        experience: 3,
        salary: '$80K'
    },
]

const FeaturedJobs = ({ isMobile }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    let profileListLastIndex = profileList.length - 1;
    
    const handleRightArrowClick = () => {
        if(currentIndex+1 <= profileListLastIndex) {
            setCurrentIndex(currentIndex+1);
        }
    }

    const handleLeftArrowClick = () => {
        if(currentIndex-1 >= 0) {
            setCurrentIndex(currentIndex-1);
        }
    }

    return (
        <Container>
            <H2>Most Demanding Job Categories</H2>
            <P2 style={{color: '#5f5f5f', textAlign: 'center', marginTop: '20px', width: isMobile ? '100%' : '70%'}}>
                The Job Support Portal is a comprehensive platform designed to assist job seekers
                with resources, guidance, and 
                opportunities to enhance their career prospects 
            </P2>
            {isMobile ?
                <ControlBox>
                    <LeftControl onClick={handleLeftArrowClick}>
                        <LeftIcon />
                    </LeftControl>
                    <RightControl onClick={handleRightArrowClick}>
                        <RightIcon />
                    </RightControl>
                </ControlBox>
                :
                <></>
            }
            {isMobile ?
            <>
                <ProfileContainer>
                    <ProfileCard
                        profileImage={profileList[currentIndex].profileImage}
                        name={profileList[currentIndex].name}
                        title={profileList[currentIndex].title}
                        stars={profileList[currentIndex].stars}
                        experience={profileList[currentIndex].experience}
                        salary={profileList[currentIndex].salary}
                    />
                </ProfileContainer>
            </>
            :
            <>
                <ProfileContainer>
                    <ProfileCard
                        profileImage={profileList[0].profileImage}
                        name={profileList[0].name}
                        title={profileList[0].title}
                        stars={profileList[0].stars}
                        experience={profileList[0].experience}
                        salary={profileList[0].salary}
                    />
                    <ProfileCard
                        profileImage={profileList[1].profileImage}
                        name={profileList[1].name}
                        title={profileList[1].title}
                        stars={profileList[1].stars}
                        experience={profileList[1].experience}
                        salary={profileList[1].salary}
                    />
                    <ProfileCard
                        profileImage={profileList[2].profileImage}
                        name={profileList[2].name}
                        title={profileList[2].title}
                        stars={profileList[2].stars}
                        experience={profileList[2].experience}
                        salary={profileList[2].salary}
                    />
                </ProfileContainer>
            </>
            }
        </Container>
    )
}

export default FeaturedJobs;