import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import Title from './Title';
import BrowseCourses from './BrowseCourses';
import EduBriqAcademy from './EduBriqAcademy';
import PopularCourse from './PopularCourse';
import OfferedCourses from './OfferedCourses';
import News from './News';
import Review from './Review';

const Academy = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, []);

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Title isMobile={isMobile} />
            <BrowseCourses isMobile={isMobile} />
            <EduBriqAcademy isMobile={isMobile} />
            <PopularCourse isMobile={isMobile} />
            <OfferedCourses isMobile={isMobile} />
            <News isMobile={isMobile} />
            <Review isMobile={isMobile} />
        </div>
    )
}

export default Academy;