import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import VideoImage from '../../../assets/images/Tutor-image1.png';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '100px',
    width: '100%',
    maxWidth: '1920px',
    padding: '0 10px'
})

const ContactUsContainer = styled(P2)({
    color: '#5f5f5f', 
    marginTop: '10px',
    textAlign: 'center'
})

const QueryAndImageContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: '50px'
})

const Queries = ({ isMobile }) => {
    const [questionPanelStatus, setQuestionPanelStatus] = useState({'q1': false, 'q2': false, 'q3': false, 'q4': false, 'q5': false});

    return (
        <Container>
            <H2 style={{fontWeight: 'bold', textAlign: 'center'}}>
                Frequently Asked Queries
            </H2>
            <ContactUsContainer> 
                Select your question with answer and watch the video. <br/>
                Still have questions? <Link to='/contact' style={{textDecoration: 'none'}}><span style={{color: '#00a0e1'}}>Contact Us</span></Link>
            </ContactUsContainer>
            <QueryAndImageContainer>
                <Box sx={{width: isMobile ? '100%' : '40%', marginRight: '20px'}}>
                    <Accordion sx={{width: '100%'}}>
                        <AccordionSummary
                            expandIcon={
                                questionPanelStatus['q1'] ? <FaMinus style={{color: 'red'}} /> : <FaPlus style={{color: 'red'}} />
                            }
                            onClick={()=>{
                                setQuestionPanelStatus({...questionPanelStatus, 'q1': !questionPanelStatus['q1']});
                            }}
                        >
                            <P1 style={{fontWeight: 'bold'}}>
                                How do I find the right tutors for my needs?
                            </P1>
                        </AccordionSummary>
                        <AccordionDetails>
                            <P3 style={{color: '#5f5f5f'}}>
                                Our platform offers a comprehensive tutor search function based on
                                subject expertise, availability and student reviews, ensuring you find the perfect match.
                            </P3>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={{width: '100%', marginTop: '20px'}}>
                        <AccordionSummary
                            expandIcon={
                                questionPanelStatus['q2'] ? <FaMinus style={{color: 'red'}} /> : <FaPlus style={{color: 'red'}} />
                            }
                            onClick={()=>{
                                setQuestionPanelStatus({...questionPanelStatus, 'q2': !questionPanelStatus['q2']});
                            }}
                        >
                            <P1 style={{fontWeight: 'bold'}}>
                                What qualifications do your tutors have?
                            </P1>
                        </AccordionSummary>
                        <AccordionDetails>
                            <P3 style={{color: '#5f5f5f'}}>
                                Our tutors are highly qualified professionals from top-ranked universities with extensive teaching experience. 
                                Their expertise and dedication ensure that students receive the best possible guidance and support in their educational journey.
                            </P3>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={{width: '100%', marginTop: '20px'}}>
                        <AccordionSummary
                            expandIcon={
                                questionPanelStatus['q3'] ? <FaMinus style={{color: 'red'}} /> : <FaPlus style={{color: 'red'}} />
                            }
                            onClick={()=>{
                                setQuestionPanelStatus({...questionPanelStatus, 'q3': !questionPanelStatus['q3']});
                            }}
                        >
                            <P1 style={{fontWeight: 'bold'}}>
                                How are tutoring sessions conducted?
                            </P1>
                        </AccordionSummary>
                        <AccordionDetails>
                            <P3 style={{color: '#5f5f5f'}}>
                                Tutoring sessions are conducted online through a user-friendly platform that allows for interactive and engaging learning experiences. 
                                Our platform supports video calls, screen sharing, and a virtual whiteboard to facilitate effective teaching and learning.
                            </P3>
                        </AccordionDetails>
                    </Accordion>
                    
                    <Accordion sx={{width: '100%', marginTop: '20px'}}>
                        <AccordionSummary
                            expandIcon={
                                questionPanelStatus['q4'] ? <FaMinus style={{color: 'red'}} /> : <FaPlus style={{color: 'red'}} />
                            }
                            onClick={()=>{
                                setQuestionPanelStatus({...questionPanelStatus, 'q4': !questionPanelStatus['q4']});
                            }}
                        >
                            <P1 style={{fontWeight: 'bold'}}>
                                What if I need to reschedule or cancel a tutoring session?
                            </P1>
                        </AccordionSummary>
                        <AccordionDetails>
                            <P3 style={{color: '#5f5f5f'}}>
                                We understand that schedules can change. If you need to reschedule or cancel a session, please notify us at least 24 hours in advance. 
                                This allows us to adjust our schedules accordingly and accommodate your needs. 
                                For more specific details, please refer to our cancellation and rescheduling policy.
                            </P3>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion sx={{width: '100%', marginTop: '20px'}}>
                        <AccordionSummary
                            expandIcon={
                                questionPanelStatus['q5'] ? <FaMinus style={{color: 'red'}} /> : <FaPlus style={{color: 'red'}} />
                            }
                            onClick={()=>{
                                setQuestionPanelStatus({...questionPanelStatus, 'q5': !questionPanelStatus['q5']});
                            }}
                        >
                            <P1 style={{fontWeight: 'bold'}}>
                                How does billing and payment work?
                            </P1>
                        </AccordionSummary>
                        <AccordionDetails>
                            <P3 style={{color: '#5f5f5f'}}>
                                Billing and payment are handled through our secure online system. 
                                You can choose from various payment options, including credit cards, debit cards, and other online payment methods. 
                                Payments are typically made in advance, and you will receive an invoice for each transaction. 
                                Detailed information about our billing process can be found on our website or by contacting our customer support team.
                            </P3>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Box sx={{width: isMobile ? '100%' : '50%', height: 'fit-content', marginTop: isMobile ? '50px' : '0'}}>
                    <img src={VideoImage} alt='video_image' style={{width: '100%'}} />
                </Box>
            </QueryAndImageContainer>
        </Container>
    )
}

export default Queries;