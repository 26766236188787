import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import RecordedClass from '../../../assets/images/icon-tutor.png';
import LiveClasses from '../../../assets/images/icon-tutor-2.png';
import PopClasses from '../../../assets/images/icon-tutor-3.png';

const Container = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    backgroundColor: '#DEFCFE',
})

const IconContainer = styled(Box)(({theme})=>({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
        width: '100%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '20%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '20%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '20%'
    }
}))

const Image = styled('img')(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        width: '50%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '30%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '60%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '60%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '60%'
    }
}))

const Features = ({ isMobile }) => {
    return (
        <Container>
            <IconContainer sx={{borderLeft: isMobile ? '' :  '1px solid #5f5f5f'}}>
                <Image src={RecordedClass} alt='recorded_class' />
                <P1 style={{textAlign: 'center', fontWeight: 'bold'}}>
                    Recorded <br />
                    Classes
                </P1>
            </IconContainer>

            <IconContainer sx={{borderLeft: isMobile ? '' : '1px solid #5f5f5f'}}>
                <Image src={LiveClasses} alt='live_classes' />
                <P1 style={{textAlign: 'center', fontWeight: 'bold'}}>
                    Live <br />
                    Classes
                </P1>
            </IconContainer>

            <IconContainer sx={{borderLeft: isMobile ? '' :  '1px solid #5f5f5f', borderRight: isMobile ? '' :  '1px solid #5f5f5f'}}>
                <Image src={PopClasses} alt='pop_classes' />
                <P1 style={{marginBottom: isMobile ? '50px' : '0', textAlign: 'center', fontWeight: 'bold'}}>
                    Pop <br />
                    Classes
                </P1>
            </IconContainer>
        </Container>
    )
}

export default Features;