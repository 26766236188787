import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import AcademyBanner from '../../../assets/images/Academy-banner.png';
import colors from '../../../utility/styles';

const Container = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: '100%',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '30px',
    padding: '0 10px'
})

const DiscoverSolutions = ({ isMobile }) => {
    return (
        <Container>
                { isMobile ? 
                <>
                    <img src={AcademyBanner} alt='image' style={{height: 'auto', width: '60%'}} />
                    <Box sx={{marginTop: '30px'}}>
                        <H2>
                            Unlock Your Career Potential <br />
                            with Tailored Online Courses!
                        </H2>
                        <P2 style={{marginTop: '30px', color: '#5f5f5f', textAlign: 'justify'}}>
                            Maximize Your Potential with Specialized Training & Custom Courses Aligned to Your Career Goals.
                        </P2>
                        
                        <Button variant='contained' sx={{marginTop: '50px'}}>
                            <P2>
                                Browse Courses
                            </P2>
                        </Button>
                    </Box>
                </>
                :
                <>
                    <Box sx={{height: 'fit-content', width: '50%'}}>
                        <H2>
                            Unlock Your Career Potential <br />
                            with Tailored Online Courses!
                        </H2>
                        <P2 style={{marginTop: '30px', color: '#5f5f5f'}}>
                            Maximize Your Potential with Specialized Training & Custom Courses Aligned to Your Career Goals.
                        </P2>

                        <Button variant='contained' sx={{marginTop: '50px'}}>
                            <P2 sx={{fontWeight: 'bold'}}>
                                Browse Courses
                            </P2>
                        </Button>
                    </Box>
                    <img src={AcademyBanner} alt='image' style={{width: '30%'}} />
                </>
                }
        </Container>
    )
}

export default DiscoverSolutions;