import { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import JobIcon1 from '../../../assets/images/Job-support-icon1.png';
import JobIcon2 from '../../../assets/images/Job-support-icon2.png';
import JobIcon3 from '../../../assets/images/Job-support-icon3.png';
import Colors from '../../../utility/styles';
import JobCard from './JobCard.js';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const JobContainer = styled(Box)(({theme})=>({
    display: 'flex',
    width: '100%',
    marginTop: '30px',

    [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        justifyContent: 'center'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        justifyContent: 'space-evenly'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        justifyContent: 'space-evenly'
    },
    [theme.breakpoints.up('xl')]: {
        justifyContent: 'space-evenly'
    }
}))

const LeftControl = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    width: '50px',
    borderRadius: '50%',

    ':hover': {
        backgroundColor: `${Colors.lightBlue}`
    },
    ':active': {
        backgroundColor: `${Colors.lightBlue}`
    }
})

const RightControl = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    width: '50px',
    borderRadius: '50%',

    ':hover': {
        backgroundColor: `${Colors.lightBlue}`
    },
    ':active': {
        backgroundColor: `${Colors.lightBlue}`
    }
})

const ControlBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-around',
    width: '200px',
    marginTop: '30px'
})

const LeftIcon = styled(SlArrowLeft)({
    fontSize: '20px',
})

const RightIcon = styled(SlArrowRight)({
    fontSize: '20px',
})

const JobTextList = [
    {
        image: JobIcon1,
        text1: 'Design &',
        text2: 'Development',
        text3: '2000 Vacancy'
    },
    {
        image: JobIcon2,
        text1: 'Sales &',
        text2: 'Marketing',
        text3: '1000 Vacancy'
    },
    {
        image: JobIcon3,
        text1: 'Business',
        text2: 'Marketing',
        text3: '1200 Vacancy'
    },
    {
        image: JobIcon3,
        text1: 'Business',
        text2: 'Development',
        text3: '3000 Vacancy'
    },
    {
        image: JobIcon1,
        text1: 'Video',
        text2: 'Editing',
        text3: '500 Vacancy'
    },
    {
        image: '',
        text1: '13k+',
        text2: 'Job Already',
        text3: 'Posted'
    }
]

const DemandingJobCategories = ({ isMobile }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    let jobTextLastIndex = JobTextList.length - 1;
    
    const handleRightArrowClick = () => {
        if(currentIndex+1 <= jobTextLastIndex) {
            setCurrentIndex(currentIndex+1);
        }
    }

    const handleLeftArrowClick = () => {
        if(currentIndex-1 >= 0) {
            setCurrentIndex(currentIndex-1);
        }
    }

    return (
        <Container>
            <H2>Most Demanding Job Categories</H2>
            {isMobile ?
                <ControlBox>
                    <LeftControl onClick={handleLeftArrowClick}>
                        <LeftIcon />
                    </LeftControl>
                    <RightControl onClick={handleRightArrowClick}>
                        <RightIcon />
                    </RightControl>
                </ControlBox>
                :
                <></>
            }
            {isMobile ?
            <>
                <JobContainer>
                    <JobCard
                        image={JobTextList[currentIndex].image}
                        text1={JobTextList[currentIndex].text1}
                        text2={JobTextList[currentIndex].text2}
                        text3={JobTextList[currentIndex].text3}
                    />
                </JobContainer>
            </>
            :
            <>
                <JobContainer>
                    <JobCard
                        image={JobTextList[0].image}
                        text1={JobTextList[0].text1}
                        text2={JobTextList[0].text2}
                        text3={JobTextList[0].text3}
                    />
                    <JobCard
                        image={JobTextList[1].image}
                        text1={JobTextList[1].text1}
                        text2={JobTextList[1].text2}
                        text3={JobTextList[1].text3}
                    />
                    <JobCard
                        image={JobTextList[2].image}
                        text1={JobTextList[2].text1}
                        text2={JobTextList[2].text2}
                        text3={JobTextList[2].text3}
                    />
                </JobContainer>

                <JobContainer sx={{marginTop: '30px'}}>
                    <JobCard
                        image={JobTextList[3].image}
                        text1={JobTextList[3].text1}
                        text2={JobTextList[3].text2}
                        text3={JobTextList[3].text3}
                    />
                    <JobCard
                        image={JobTextList[4].image}
                        text1={JobTextList[4].text1}
                        text2={JobTextList[4].text2}
                        text3={JobTextList[4].text3}
                    />
                    <JobCard
                        text1={JobTextList[5].text1}
                        text2={JobTextList[5].text2}
                        text3={JobTextList[5].text3}
                        backgroundColor='#cff1fc'
                    />
                </JobContainer>
            </>
            }
        </Container>
    )
}

export default DemandingJobCategories;