import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import AcademyBanner2 from '../../../assets/images/Academy-banner2.png';
import colors from '../../../utility/styles';

const Container = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '200px',
    padding: '0 10px',

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginTop: '450px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        flexDirection: 'column',
        marginTop: '450px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        flexDirection: 'row'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        flexDirection: 'row'
    },
    [theme.breakpoints.up('xl')]: {
        flexDirection: 'row'
    }
}))

const TextContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '50%',

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: '30px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%',
        marginTop: '30px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '50%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '50%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '50%'
    }
}))

const RegisterButton = styled(Button)({
    marginTop: '30px', 
    backgroundColor: 'orange',

    ':hover': {
        backgroundColor: 'orange'
    }
})

const PopularCourse = ({isMobile}) => {
    return (
        <Container>
            {isMobile ?
                <>
                    <img src={AcademyBanner2} alt='academy_banner2' style={{width: '50%'}}/>
                    <TextContainer>
                        <Box sx={{backgroundColor: 'orange', padding: '5px', borderRadius: '5px'}}>
                            <H5 style={{color: 'white'}}>
                                Most Popular Course
                            </H5>
                        </Box>
                        <H2>
                            ACCESSIBILITY TESTING
                        </H2>
                        <P2 style={{marginTop: '50px'}}>
                            Become an expert in Accessibility Testing by practical
                            learning of WCAG principles and guidelines and conduct
                            testing using various screen readers and tools.
                        </P2>
                        <Box sx={{marginTop: '30px', marginLeft: '15px'}}>
                            <ul>
                                <li className='point'>Regular Class 60 Hours</li>
                                <li className='point'>Student Support Session: 10 Hours</li>
                                <li className='point'>Weekend based Online Classes</li>
                                <li className='point'>Job Placement Support</li>
                            </ul>
                        </Box>
                        <RegisterButton variant='contained'>
                            <P3>
                                Register Now!
                            </P3>
                        </RegisterButton>
                    </TextContainer>
                </>
                :
                <>
                    <TextContainer>
                        <Box sx={{backgroundColor: 'orange', padding: '5px', borderRadius: '5px'}}>
                            <H5 style={{color: 'white'}}>
                                Most Popular Course
                            </H5>
                        </Box>
                        <H2>
                            ACCESSIBILITY TESTING
                        </H2>
                        <P2 style={{marginTop: '50px'}}>
                            Become an expert in Accessibility Testing by practical
                            learning of WCAG principles and guidelines and conduct
                            testing using various screen readers and tools.
                        </P2>
                        <Box sx={{marginTop: '30px', marginLeft: '15px'}}>
                            <ul>
                                <li className='point'>Regular Class 60 Hours</li>
                                <li className='point'>Student Support Session: 10 Hours</li>
                                <li className='point'>Weekend based Online Classes</li>
                                <li className='point'>Job Placement Support</li>
                            </ul>
                        </Box>
                        <RegisterButton variant='contained'>
                            <P3>
                                Register Now!
                            </P3>
                        </RegisterButton>
                    </TextContainer>
                    <img src={AcademyBanner2} alt='academy_banner2' style={{width: '30%'}}/>
                </>
            }
        </Container>
    )
}

export default PopularCourse;