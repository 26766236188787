import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import Title from './Title';
import Details from './Details';
import Industries from './Industries';
import WhyUs from './WhyUs';
import Demo from './Demo';

const Lms = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, []);

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Title isMobile={isMobile} />
            <Details isMobile={isMobile} />
            <Industries isMobile={isMobile} />
            <WhyUs isMobile={isMobile} />
            <Demo isMobile={isMobile} />
        </div>
    )
}

export default Lms;