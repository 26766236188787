import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/system';
import { Grid, Drawer } from '@mui/material';
import EduBriqLogo from '../images/Logo.png';
import { Hyperlink, NavigationLink } from '../components/Hyperlink';
import { useState, useEffect, useRef } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { FiMenu } from "react-icons/fi";
import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../utility/Typography';

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     backgroundColor: 'transparent',
//     boxShadow: 'none',
//   },
//   title: {
//     flexGrow: 1,
//   },
//   button: {
//     color: theme.palette.primary.contrastText,
//   },
// }));

const pages = ['Home', 'Services', 'Solutions', 'About Us', 'Contact'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const NavbarContainer = styled(Box)(({theme}) => ({
  display: 'flex',
  width: '100%',
}))

const BrochureButton = styled(Button)({
  color: 'white',
  backgroundColor: 'orange',
  width: '200px',
  marginLeft: '10px',

  ':hover': {
    backgroundColor: 'orange'
  }
})

function NavBar(props) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorSolutionEl, setAnchorSolutionEl] = React.useState(null);
  const serviceRef = useRef();
  const solutionRef = useRef();
  const open = Boolean(anchorEl);
  const openSolution = Boolean(anchorSolutionEl);

  const handleClick = (event) => {
    setAnchorEl(serviceRef.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSolutionClick = (event) => {
    setAnchorSolutionEl(solutionRef.current);
  };

  const handleSolutionClose = () => {
    setAnchorSolutionEl(null);
  };

  const handleDrawer = () => {
    setOpenDrawer(!openDrawer);
  }

  const StyledAppBar = styled(AppBar)({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    zIndex: 1000,
    position: 'relative',
  });

  const StyledButton = styled(Button)(({ theme }) => ({

    color: 'black',
  }));

  // const Logo = styled('img')(
  //   ({ theme }) => `
  //   cursor: pointer;
  //   height: ${theme.spacing(11)};
  //   margin: ${theme.spacing(1)};
  //   `
  // )

  const Logo = styled('img')({
    width: '300px',
  })

  return (
    <NavbarContainer>
      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
        <NavigationLink to='/'><Logo src={EduBriqLogo} /></NavigationLink>
        {isMobile ?
        <Box sx={{marginRight: '20px'}}>
          <FiMenu size='30px' onClick={handleDrawer} />
          <Drawer open={openDrawer} onClose={handleDrawer} anchor='right'>
            <Box sx={{width: '250px', display: 'flex', flexDirection: 'column'}}>
              
              <NavigationLink to='/' onClick={handleDrawer}><Logo src={EduBriqLogo} sx={{width: '200px', height: 'auto', marginBottom: '50px'}} /></NavigationLink>

              <BrochureButton variant='contained'>
                <P3>Downlaod Brochure</P3>
              </BrochureButton>

              <NavigationLink to='/' onClick={handleDrawer} sx={{margin: '50px 0 0 0'}}>
                <StyledButton>Home</StyledButton>
              </NavigationLink>
              {/* <br /> */}

              <Accordion sx={{boxShadow: 'none', marginTop: '50px'}}>
                <AccordionSummary className='Navbar-accordion-summary' sx={{padding: '0'}}>
                  <Button sx={{color: 'black'}}>
                    Services
                  </Button>
                </AccordionSummary>
                <AccordionDetails>
                  <NavigationLink to='/lms' onClick={handleDrawer}>
                    <span style={{color: 'black'}}>LMS</span>
                  </NavigationLink> <br /><br />
                  <NavigationLink to='/exam' onClick={handleDrawer}>
                    <span style={{color: 'black'}}>Exam</span>
                  </NavigationLink> <br /><br />
                  <NavigationLink to='/tutor' onClick={handleDrawer}>
                    <span style={{color: 'black'}}>Tutor</span>
                  </NavigationLink>
                </AccordionDetails>
              </Accordion>

              <Accordion sx={{boxShadow: 'none', '::before': {display: 'none'}, marginTop: '50px'}}>
                <AccordionSummary className='Navbar-accordion-summary' sx={{padding: '0'}}>
                  <Button sx={{color: 'black'}}>
                    Solutions
                  </Button>
                </AccordionSummary>
                <AccordionDetails>
                  <NavigationLink to='/counselling' onClick={handleDrawer}>
                    <span style={{color: 'black'}}>Counseling</span>
                  </NavigationLink> <br /><br />
                  <NavigationLink to='/academy' onClick={handleDrawer}>
                    <span style={{color: 'black'}}>Academy</span>
                  </NavigationLink> <br /><br />
                  <NavigationLink to='/jobsupport' onClick={handleDrawer}>
                    <span style={{color: 'black'}}>Job Support</span>
                  </NavigationLink>
                </AccordionDetails>
              </Accordion>
              
              <NavigationLink to='/about' onClick={handleDrawer} sx={{margin: '50px 0 0 0'}}><StyledButton>About Us</StyledButton></NavigationLink>
              
              <NavigationLink to='/contact' onClick={handleDrawer} sx={{margin: '50px 0 0 0'}}><StyledButton>Contact</StyledButton></NavigationLink>
            </Box>
          </Drawer>
        </Box>
        :
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: 'calc(100% - 300px)'
          }}
        >
          <NavigationLink to='/'><StyledButton>Home</StyledButton></NavigationLink>
 
            <Button
              onClick={handleClick}
              onMouseMove={handleClick}
              onMouseOut={handleClose}
              sx={{color: 'black', zIndex: '2000'}}
              ref={serviceRef}
            >
              Services
            </Button>

            <Button
              onClick={handleSolutionClick}
              onMouseMove={handleSolutionClick}
              onMouseOut={handleSolutionClose}
              sx={{color: 'black', zIndex: '2000'}}
              ref={solutionRef}
            >
              Solutions
            </Button>

          <NavigationLink to='/about'><StyledButton>About Us</StyledButton></NavigationLink>
          <NavigationLink to='/contact'><StyledButton>Contact</StyledButton></NavigationLink>

        </Box>        
        }
      </Box>


      
      <Menu
        // sx={{ marginTop: "50px", marginLeft: "520px" }}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        slotProps={{
          paper: {
            onMouseMove: handleClick,
            onMouseOut: handleClose
          }
        }}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'right',
        // }}
        // transformOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'right',
        // }}
      >
        <NavigationLink to='/lms'><MenuItem onClick={handleClose}><span style={{color: 'black'}}>LMS</span></MenuItem></NavigationLink>
        <NavigationLink to='/exam'><MenuItem onClick={handleClose}><span style={{color: 'black'}}>Exam</span></MenuItem></NavigationLink>
        <NavigationLink to='/tutor'><MenuItem onClick={handleClose}><span style={{color: 'black'}}>Tutor</span></MenuItem></NavigationLink>
      </Menu>



      <Menu
        // sx={{ marginTop: "50px", marginLeft: "800px" }}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorSolutionEl}
        open={openSolution}
        onClose={handleSolutionClose}
        disableScrollLock={true}
        slotProps={{
          paper: {
            onMouseMove: handleSolutionClick,
            onMouseOut: handleSolutionClose
          }
        }}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'right',
        // }}
        // transformOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'right',
        // }}
      >
        <NavigationLink to='/counselling'><MenuItem onClick={handleSolutionClose}><span style={{color: 'black'}}>Counseling</span></MenuItem></NavigationLink>
        <NavigationLink to='/academy'><MenuItem onClick={handleSolutionClose}><span style={{color: 'black'}}>Academy</span></MenuItem></NavigationLink>
        <NavigationLink to='/jobsupport'><MenuItem onClick={handleSolutionClose}><span style={{color: 'black'}}>Job Support</span></MenuItem></NavigationLink>
      </Menu>



      
    </NavbarContainer>
    // <React.Fragment>
    //   <CssBaseline />
    //   <ElevationScroll {...props}>
    //     <AppBar position="static"
    //       sx={{
    //         backgroundColor: 'transparent'
    //       }}
    //     >
    //       <Container maxWidth="xl">
    //         <Grid item xl={6} lg={6} sm={6} xs={12}>
    //           <Box
    //             sx={{
    //               display: 'flex',
    //               justifyContent: 'end',
    //               alignItems: 'end'
    //             }}
    //           >
    //             <Typography variant='h6' sx={{ color: 'black' }}>Welcome to <b>NextGen MyHealth VCP</b></Typography>
    //           </Box>

    //         </Grid>
    //         <Toolbar disableGutters>
    //           <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
    //           <Typography
    //             variant="h6"
    //             noWrap
    //             component="a"
    //             href="#app-bar-with-responsive-menu"
    //             sx={{
    //               mr: 2,
    //               display: { xs: 'none', md: 'flex' },
    //               fontFamily: 'monospace',
    //               fontWeight: 700,
    //               letterSpacing: '.3rem',
    //               color: 'inherit',
    //               textDecoration: 'none',
    //             }}
    //           >
    //             LOGO
    //           </Typography>

    //           <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
    //             <IconButton
    //               size="large"
    //               aria-label="account of current user"
    //               aria-controls="menu-appbar"
    //               aria-haspopup="true"
    //               onClick={handleOpenNavMenu}
    //               color="inherit"
    //             >
    //               <MenuIcon />
    //             </IconButton>
    //             <Menu
    //               id="menu-appbar"
    //               anchorEl={anchorElNav}
    //               anchorOrigin={{
    //                 vertical: 'bottom',
    //                 horizontal: 'left',
    //               }}
    //               keepMounted
    //               transformOrigin={{
    //                 vertical: 'top',
    //                 horizontal: 'left',
    //               }}
    //               open={Boolean(anchorElNav)}
    //               onClose={handleCloseNavMenu}
    //               sx={{
    //                 display: { xs: 'block', md: 'none' },
    //               }}
    //             >
    //               {pages.map((page) => (
    //                 <MenuItem key={page} onClick={handleCloseNavMenu}>
    //                   {/* <Typography textAlign="center">{page}</Typography> */}
    //                   <AppBarItems>{page}</AppBarItems>
    //                 </MenuItem>
    //               ))}
    //             </Menu>
    //           </Box>
    //           <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
    //           <Typography
    //             variant="h5"
    //             noWrap
    //             component="a"
    //             href="#app-bar-with-responsive-menu"
    //             sx={{
    //               mr: 2,
    //               display: { xs: 'flex', md: 'none' },
    //               flexGrow: 1,
    //               fontFamily: 'monospace',
    //               fontWeight: 700,
    //               letterSpacing: '.3rem',
    //               color: 'inherit',
    //               textDecoration: 'none',
    //               justifyContent: 'end',
    //             }}
    //           >
    //             LOGO
    //           </Typography>
    //           <Box sx={{ flexGrow: 1, justifyContent: 'end', display: { xs: 'none', md: 'flex' } }}>
    //             {pages.map((page) => (
    //               <Button
    //                 key={page}
    //                 onClick={handleCloseNavMenu}
    //                 sx={{ mx: 6, my: 2, color: 'white', display: 'block' }}
    //               >
    //                 <AppBarItems>{page}</AppBarItems>
    //               </Button>
    //             ))}
    //           </Box>
    //         </Toolbar>
    //       </Container>
    //     </AppBar>
    //   </ElevationScroll>
    // </React.Fragment>
  );
}
export default NavBar;