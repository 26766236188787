import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import Title from './Title';
import BrowseCourses from './BrowseCourses';
import HowItWorks from './HowItWorks';
import ExclusiveFeatures from './ExclusiveFeatures';
import DemandingJobCategories from './DemandingJobCategories';
import FeaturedJobs from './FeaturedJobs';
import HireTalents from './HireTalents';
import Review from './Review';

const JobSupport = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, []);

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Title isMobile={isMobile} />
            <BrowseCourses isMobile={isMobile} />
            <HowItWorks isMobile={isMobile} />
            <ExclusiveFeatures isMobile={isMobile} />
            <DemandingJobCategories isMobile={isMobile} />
            <FeaturedJobs isMobile={isMobile} />
            <HireTalents isMobile={isMobile} />
            <Review isMobile={isMobile} />
        </div>
    )
}

export default JobSupport;