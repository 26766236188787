import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import Title from './Title';
import Demo from './Demo';
import Features from './Features';
import ExclusiveFeatures from './ExclusiveFeatures';
import Queries from './Queries';

const ExamManagement = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, []);

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Title isMobile={isMobile} />
            <Demo isMobile={isMobile} />
            <Features isMobile={isMobile} />
            <ExclusiveFeatures isMobile={isMobile} />
            <Queries isMobile={isMobile} />
        </div>
    )
}

export default ExamManagement;