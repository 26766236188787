import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import TutorBanner from '../../../assets/images/Tutor-banner.png';

const Container = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '30px',
    padding: '0 10px'
})

const Demo = ({ isMobile }) => {
    return (
        <Container>
            {isMobile ?
            <>
            <img src={TutorBanner} alt='image' style={{width: '80%'}} />
            <Box sx={{width: '100%'}}>
                <H2>
                    Empower Learning with <br />
                    Seamless Student Management
                </H2>
                <P2 style={{marginTop: '20px', color: '#5f5f5f'}}>
                    Efficient Tools for Seamless Connection - Connecting Tutors and Students
                    Anytime, Anywhere with User-Friendly Management Solutions 
                </P2>
                <Button variant='contained' sx={{marginTop: '30px'}}>
                    <P2>
                        Request For Demo
                    </P2>
                </Button>
            </Box>
            </>
            :
            <>
            <Box sx={{width: '50%'}}>
                <H2>
                    Empower Learning with <br />
                    Seamless Student Management
                </H2>
                <P2 style={{marginTop: '20px', color: '#5f5f5f'}}>
                    Efficient Tools for Seamless Connection - Connecting Tutors and Students
                    Anytime, Anywhere with User-Friendly Management Solutions 
                </P2>
                <Button variant='contained' sx={{marginTop: '100px'}}>
                    <P2>
                        Request For Demo
                    </P2>
                </Button>
            </Box>
            <img src={TutorBanner} alt='image' style={{width: '30%'}} />
            </>
            }
        </Container>
    )
}

export default Demo;