import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import CounsellingBanner from '../../../assets/images/Counselling-banner.png';
import colors from '../../../utility/styles';

const Container = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '30px',
    padding: '0 10px',
})

const DiscoverSolutions = ({ isMobile }) => {
    return (
        <Container>
                { isMobile ? 
                <>
                    <img src={CounsellingBanner} alt='image' style={{ width: '100%'}} />
                    <Box sx={{marginTop: '30px'}}>
                        <H2>
                            Not Just Traditional <br />
                            Visa & Immigration Firm!
                        </H2>
                        <P2 style={{marginTop: '30px', color: '#5f5f5f', textAlign: 'justify'}}>
                            Beyond a traditional Visa and Immigration Firm: We specialized in personalized,
                            comprehensive immigration solutions tailored to your unique journey.
                        </P2>
                        
                        <Button variant='contained' sx={{marginTop: '50px'}}>
                            <P2>
                                Discover Solutions
                            </P2>
                        </Button>
                    </Box>
                </>
                :
                <>
                    <Box sx={{height: 'fit-content', width: '50%'}}>
                        <H2>
                            Not Just Traditional <br />
                            Visa & Immigration Firm!
                        </H2>
                        <P2 style={{marginTop: '30px', color: '#5f5f5f'}}>
                            Beyond a traditional Visa and Immigrationf Firm: We specialized in personalized,
                            comprehensive immigration solutions tailored to your unique journey.
                        </P2>

                        <Button variant='contained' sx={{marginTop: '50px'}}>
                            <P2 sx={{fontWeight: 'bold'}}>
                                Discover Solutions
                            </P2>
                        </Button>
                    </Box>
                    <img src={CounsellingBanner} alt='image' style={{width: '30%'}} />
                </>
                }
        </Container>
    )
}

export default DiscoverSolutions;