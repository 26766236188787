import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import StudentIcon from '../../../assets/images/About-Us-icon-2.png';
import TrainingIcon from '../../../assets/images/About-Us-icon-1.png';
import TutorIcon from '../../../assets/images/About-Us-icon-4.png';
import SolutionIcon from '../../../assets/images/About-Us-icon-3.png';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px'
})

const FootPrintContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '30px'
})

const IconContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
})

const Footprints = () => {
    return (
        <Container>
            <H2 variant='h4' sx={{fontWeight: 'bold', textAlign: 'center'}}>
                EduBriq Global Footprints
            </H2>
            <FootPrintContainer>
                <IconContainer>
                    <img src={StudentIcon} alt='student icon' />
                    <H3 style={{fontWeight: 'bold', color: '#5f5f5f'}}>
                        350+
                    </H3>
                    <P2 style={{color: '#5f5f5f'}}>
                        Student Served
                    </P2>
                </IconContainer>

                <IconContainer>
                    <img src={TrainingIcon} alt='training icon' />
                    <H3 style={{fontWeight: 'bold', color: '#5f5f5f'}}>
                        1000+
                    </H3>
                    <P2 style={{color: '#5f5f5f'}}>
                        Hours of Training
                    </P2>
                </IconContainer>

                <IconContainer>
                    <img src={TutorIcon} alt='tutor icon' />
                    <H3 style={{fontWeight: 'bold', color: '#5f5f5f'}}>
                        100+
                    </H3>
                    <P2 style={{color: '#5f5f5f'}}>
                        Online Tutors
                    </P2>
                </IconContainer>

                <IconContainer>
                    <img src={SolutionIcon} alt='solution icon' />
                    <H3 style={{fontWeight: 'bold', color: '#5f5f5f'}}>
                        50+
                    </H3>
                    <P2 style={{color: '#5f5f5f'}}>
                        Solutions Provided
                    </P2>
                </IconContainer>
            </FootPrintContainer>
        </Container>
    )
}

export default Footprints;