import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
    padding: '20px'
})

const CourseCard = (props) => {
    return (
        <Container>
            <img src={props.image} alt='course_image' />
            <P2 style={{fontWeight: 'bold'}}>
                {props.name}
            </P2>
            <Box sx={{display: 'flex', marginTop: '30px'}}>
                <P3 style={{color: '#5f5f5f'}}>
                    {props.startDate} <span style={{marginLeft: '20px', color: 'red', fontSize: '20px'}}>{props.price}</span>
                </P3>
            </Box>  
        </Container>
    )
}

export default CourseCard;