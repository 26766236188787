import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import Australia from '../../../assets/images/Australia.png';
import Sweden from '../../../assets/images/Sweden.png';
import Newzealand from '../../../assets/images/Newzealand.png';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const ImageContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '30px',

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        height: '100vh',
        maxHeight: '700px',
        alignItems: 'center'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        flexDirection: 'column',
        height: '1000px',
        alignItems: 'center'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    [theme.breakpoints.up('xl')]: {
        flexDirection: 'row',
        justifyContent: 'space-around'
    }
}))

const Image = styled('img')(({theme}) => ({
    height: 'auto',
    width: '20%',

    [theme.breakpoints.down('sm')]: {
        width: '60%',
        maxWidth: '250px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '50%',
        maxWidth: '350px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '20%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '20%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '20%'
    }
}))

const Destination = () => {
    return (
        <Container>
            <H2>Choose Your Destination</H2>
            <ImageContainer>
                <Image src={Australia} alt='australia_image' />
                <Image src={Sweden} alt='sweden_image' />
                <Image src={Newzealand} alt='newzealand_image' />
            </ImageContainer>
        </Container>
    )
}

export default Destination;