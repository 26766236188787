import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';

const Card = styled(Box)(({theme, backgroundColor})=>({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '300px',
    backgroundColor: backgroundColor ? backgroundColor : 'white',
    color: '#5f5f5f',
    padding: '5px',
    boxShadow: '0px 5px 5px -5px #5f5f5f',
    zIndex: '2',

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '200px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '30%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '25%',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '25%',
    },
    [theme.breakpoints.up('xl')]: {
        width: '25%',
    }
}))

const ServiceCard = (props) => {
    return (
        <Card backgroundColor={props.backgroundColor}>
            <img src={props.icon} alt='icon' />
            <P1 style={{marginTop: '20px', textAlign: 'center', fontWeight: props.fontWeight ? props.fontWeight : 'normal'}}>
                {props.title1} <br />
                {props.title2}
            </P1>

            <P2 style={{marginTop: '20px', textAlign: 'center'}}>
                {props.text}
            </P2>
        </Card>
    )  
}

export default ServiceCard;