import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const ItemContainer = styled(Box)((props) => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    flexWrap: 'wrap',
    marginTop: props.marginTop ? `${props.marginTop}` : '0px'
}))

const Item = styled(Box)(({ theme, marginTop }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: marginTop ? `${marginTop}` : '0px',

    [theme.breakpoints.down('sm')]: {
        width: '100%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '430px'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '430px'
    },
    [theme.breakpoints.up('xl')]: {
        width: '430px'
    }
}))

const ItemNumber = styled(Box)(({theme, backgroundColor}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: backgroundColor ? `${backgroundColor}` : 'none',
    borderRadius: '50%',

    [theme.breakpoints.down('sm')]: {
        height: '40px',
        width: '40px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        height: '50px',
        width: '50px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        height: '60px',
        width: '60px'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        height: '60px',
        width: '60px'
    },
    [theme.breakpoints.up('xl')]: {
        height: '60px',
        width: '60px'
    }
}))

const ItemText = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '30px',

    [theme.breakpoints.down('sm')]: {
        width: '180px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '180px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '330px'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '330px'
    },
    [theme.breakpoints.up('xl')]: {
        width: '330px'
    }
}))

const Process = ({ isMobile }) => {
    return (
        <Container>
            <H2 style={{textAlign: 'center'}}>
                Dependable and Trusworthy Process
            </H2>
            <ItemContainer marginTop={isMobile ? '30px' : '100px'}>
                <Item>
                    <ItemNumber backgroundColor='#bde4ff'>
                        <H4>
                            1
                        </H4>
                    </ItemNumber>
                    <ItemText>
                        <H5>
                            Choose your visa type
                        </H5>
                        <P2>
                            Determine the visa type for <br />
                            your travel purpose.
                        </P2>
                    </ItemText>
                </Item>

                <Item marginTop={isMobile ? '30px' : '0px'}>
                    <ItemNumber backgroundColor='#fe6268'>
                        <H4>
                            2
                        </H4>
                    </ItemNumber>
                    <ItemText>
                        <H5>
                            Contact our branches
                        </H5>
                        <P2>
                            Start your transaction by <br />
                            applying to our branches.
                        </P2>
                    </ItemText>
                </Item>
            </ItemContainer>

            <ItemContainer marginTop='30px'>
                <Item>
                    <ItemNumber backgroundColor='#c6ffbd'>
                        <H4>
                            3
                        </H4>
                    </ItemNumber>
                    <ItemText>
                        <H5>
                            Submit all your documents
                        </H5>
                        <P2>
                            Collect all the required <br />
                            documents the process.
                        </P2>
                    </ItemText>
                </Item>

                <Item marginTop={isMobile ? '30px' : '0px'}>
                    <ItemNumber backgroundColor='#ff8800'>
                        <H4>
                            4
                        </H4>
                    </ItemNumber>
                    <ItemText>
                        <H5>
                            Passport delivery
                        </H5>
                        <P2>
                            Receive your visa, which is <br />
                            finalized after application.
                        </P2>
                    </ItemText>
                </Item>
            </ItemContainer>
        </Container>
    )
}

export default Process;