import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import Colors from '../../../utility/styles.js';

const Container = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    backgroundColor: Colors.lightBlue,

    [theme.breakpoints.down('sm')]: {
        height: 'fit-content'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        height: 'fit-content'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        height: '250px'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        height: '250px'
    },
    [theme.breakpoints.up('xl')]: {
        height: '250px'
    }
}))

const TextContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'conter',
    alignItems: 'center',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
        height: '10vh'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        height: '10vh'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        height: '250px'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        height: '250px'
    },
    [theme.breakpoints.up('xl')]: {
        height: '250px'
    }
}))

const News = () => {
    return (
        <Container>
            <TextContainer>
                <H4>
                    Want to Recieve Fresh News?
                </H4>
            </TextContainer>
            <TextContainer sx={{width: '200px'}}>
                <P2 style={{color: '#5f5f5f'}}>
                    Subscribe to our daily newsletter to stay
                    ahead of the trending courses and
                    updates
                </P2>
            </TextContainer>
            <Button variant='contained' sx={{backgroundColor: 'orange', ':hover': {backgroundColor: 'orange'}}}>
                <H6>
                    Subscribe Now!
                </H6>
            </Button>
        </Container>
    )
}

export default News;