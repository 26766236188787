import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { GoClock } from "react-icons/go";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import LocationIcon from '../../../assets/images/Job-support-location.png';
import colors from '../../../utility/styles';

const Card = styled(Box)((props)=>({
    width: '300px',
    height: '300px',
    padding: '10px',
    border: '1px solid black',
    borderRadius: '5px'
}))

const CompanyNameContainer = styled(Box)({
    display: 'flex',
    width: '100%'
})

const CompanyLocation = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginTop: '50px'
})

const DetailButton = styled(Button)({
    backgroundColor: '#f6f6f6', 
    color: '#5f5f5f',
    width: '120px',

    ':hover': {
        backgroundColor: '#f6f6f6'
    }
})

const ApplyButton = styled(Button)({
    backgroundColor: '#cff1fc', 
    color: '#5f5f5f',
    width: '120px',

    ':hover': {
        backgroundColor: '#cff1fc'
    }
})

const JobCard = (props) => {
    return (
        <Card>
            <CompanyNameContainer>
                <img src={props.CompanyIcon} alt='company_icon' style={{width: '32%'}} />
                <Box sx={{width: '100%', marginLeft: '10px'}}>
                    <P1 style={{fontSize: '18px', color: '#5f5f5f'}}>
                        {props.CompanyName}
                    </P1>
                    <P1 style={{fontSize: '20px', color: '#00a0e1'}}>
                        {props.JobCategory}
                    </P1>
                </Box>
            </CompanyNameContainer>

            <CompanyLocation>
                <img src={LocationIcon} alt='location_icon' style={{width: '10%'}} />
                <P1 style={{fontSize: '18px', color: '#5f5f5f'}}>
                    {props.Location}
                </P1>
                <GoClock style={{marginLeft: '10px'}} />
                <P1 style={{fontSize: '18px', marginLeft: '10px', color: '#5f5f5f'}}>
                    {props.JobType}
                </P1>
            </CompanyLocation>

            <P1 style={{marginTop: '30px', marginLeft: '10px', fontSize: '30px'}}>
                {props.Salary}<span style={{fontSize: '15px', color: '#5f5f5f'}}>/Year</span>
            </P1>

            <Box sx={{display: 'flex', marginTop: '20px', justifyContent: 'space-evenly'}}>
                <DetailButton variant='contained'>
                    <P4 style={{color: '#5f5f5f'}}>
                        View Detail
                    </P4>
                </DetailButton>
                <ApplyButton variant='contained'>
                    <P4 style={{color: '#5f5f5f'}}>
                        Apply
                    </P4>
                </ApplyButton>
            </Box>
        </Card>
    )
}

export default JobCard;