import React from 'react';
import { Grid, Typography, Box, IconButton, Divider } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import FeatureCard from './FeatureCard';
import { styled } from '@mui/system';

const BlueLine = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#2196F3', // Blue color
    transform: 'translateY(-50%)',
}));


const features = [
    { title: 'Edubriq LMS', description: 'Transform your learning experience with our state-of-the-art Learning Management System, designed to deliver engaging and interactive online courses.', highlighted: false },
    { title: 'Edubriq Exam', description: 'Achieve exam success with our comprehensive test preparation tools and resources, tailored to help you excel in your academic and professional assessments.', highlighted: true },
    { title: 'Edubriq Tutor', description: 'Connect with expert tutors for personalized, one-on-one instruction and support, helping you master subjects and achieve your learning goals.', highlighted: false },
    { title: 'Edubriq Academy', description: 'Explore a diverse range of courses and programs at EduBriq Academy, where quality education meets flexible learning opportunities.', highlighted: false },
    { title: 'Edubriq Counseling', description: 'Navigate your educational and career journey with confidence through our dedicated counseling services, providing guidance and support every step of the way.', highlighted: false },
    { title: 'EduBriq Job Support', description: 'Transition seamlessly from education to employment with our job support services, including resume building, interview preparation, and job placement assistance.', highlighted: false },
];

const Features = () => {
    return (
        <Box sx={{
            mt: 5,
            mb: 5
        }} >
            <Typography variant="h4" gutterBottom textAlign="center">
                Explore The Unlimited Possibilities
            </Typography>
            <Grid container>
                {features.map((feature, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box position="relative">
                            <Divider
                                color='#BDEAFD'
                                sx={{
                                    borderBottomWidth: 14,

                                }}
                                style={{ width: '100%', position: 'absolute', top: '50%', zIndex: 0 }} />
                            <Box
                                zIndex={1}
                                sx={{
                                    paddingLeft: 8,
                                    paddingRight: 8
                                }}
                            // justifyContent="center" alignItems="center"
                            >
                                <FeatureCard
                                    title={feature.title}
                                    description={feature.description}
                                    highlighted={feature.highlighted}
                                />
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default Features