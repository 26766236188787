import { useState } from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import Colors from '../../../utility/styles.js';
import CourseImage1 from '../../../assets/images/Academy-course1.png';
import CourseImage2 from '../../../assets/images/Academy-course2.png';
import CourseImage3 from '../../../assets/images/Academy-course3.png';
import CourseImage4 from '../../../assets/images/Academy-course4.png';
import CourseCard from './CourseCard.js';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px',
})

const TopicContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    height: '70px',
    marginTop: '50px'
})

const Topic = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '150px',
    cursor: 'pointer',
})

const CourseContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    flexWrap: 'wrap'
})

const CourseList = {
    1: [
        {
            image: CourseImage1,
            name: 'Network Security and Configuration',
            startDate: 'Started on 10.14.2019',
            price: '$380'
        },
        {
            image: CourseImage2,
            name: 'Marketing Strategies for Businesses',
            startDate: 'Started on 10.28.2019',
            price: '$360'
        },
        {
            image: CourseImage3,
            name: 'Social Media and Web Design Technology',
            startDate: 'Started on 11.11.2019',
            price: '$290'
        },
        {
            image: CourseImage4,
            name: 'WordPress Development with Bootstrap',
            startDate: 'Started on 12.9.2019',
            price: 'FREE'
        }
    ],
    2: [
        {
            image: CourseImage1,
            name: 'Network Security and Configuration',
            startDate: 'Started on 10.14.2019',
            price: '$380'
        },
        {
            image: CourseImage1,
            name: 'Network Security and Configuration',
            startDate: 'Started on 10.14.2019',
            price: '$370'
        },
        {
            image: CourseImage1,
            name: 'Network Security and Configuration',
            startDate: 'Started on 10.14.2019',
            price: '$360'
        },
        {
            image: CourseImage1,
            name: 'Network Security and Configuration',
            startDate: 'Started on 10.14.2019',
            price: '$350'
        }
    ]
}

const OfferedCourses = ({ isMobile }) => {
    const [topic, setTopic] = useState(1);

    const handleTopicClick = (top) => {
        setTopic(top);
    }

    return (
        <Container>
            <H2 style={{color: '#5f5f5f'}}>
                Our Offered <span style={{color: '#00a0e1'}}>Courses</span>
            </H2>
            <TopicContainer>
                <Topic  
                    onClick={()=>{
                        handleTopicClick(1);
                    }}
                    sx={{
                        color: topic===1 ? '#5f5f5f' : 'white',
                        backgroundColor: topic===1 ? Colors.secondaryLightBlue : '#5f5f5f'
                    }}
                >
                    <H4>
                        Topic A
                    </H4>
                </Topic>
                <Topic
                    onClick={()=>{
                        handleTopicClick(2);
                    }}
                    sx={{
                        color: topic===2 ? '#5f5f5f' : 'white',
                        backgroundColor: topic===2 ? Colors.secondaryLightBlue : '#5f5f5f'
                    }}
                >
                    <H4>
                        Topic B
                    </H4>
                </Topic>
                <Topic
                    onClick={()=>{
                        handleTopicClick(3);
                    }}
                    sx={{
                        color: topic===3 ? '#5f5f5f' : 'white',
                        backgroundColor: topic===3 ? Colors.secondaryLightBlue : '#5f5f5f'
                    }}
                >
                    <H4>
                        Topic C
                    </H4>
                </Topic>
                <Topic
                    onClick={()=>{
                        handleTopicClick(4);
                    }}
                    sx={{
                        color: topic===4 ? '#5f5f5f' : 'white',
                        backgroundColor: topic===4 ? Colors.secondaryLightBlue : '#5f5f5f'
                    }}
                >
                    <H4>
                        Topic D
                    </H4>
                </Topic>
            </TopicContainer>

            <CourseContainer>
                {
                    CourseList[topic]?.map((course)=>{
                        return <CourseCard 
                                    image={course.image}
                                    name={course.name}
                                    startDate={course.startDate}
                                    price={course.price}
                                />
                    })
                }
            </CourseContainer>
        </Container>
    )
}

export default OfferedCourses;