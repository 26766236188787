import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const ContactUsContainer = styled(P2)({
    color: '#5f5f5f', 
    marginTop: '10px',
    textAlign: 'center'
})

const QuestionContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    align: 'center',
    width: '100%',
    maxWidth: '1000px',
    marginTop: '30px'
})

const Queries = () => {
    const [questionPanelStatus, setQuestionPanelStatus] = useState({'q1': false, 'q2': false, 'q3': false, 'q4': false, 'q5': false});

    return (
        <Container>
            <H2>
                Frequently Asked Queries
            </H2>
            <ContactUsContainer> 
                We tried to give few common question's answer <br/>
                Still have questions? <Link to='/contact' style={{textDecoration: 'none'}}><span style={{color: '#00a0e1'}}>Contact Us</span></Link>
            </ContactUsContainer>
            <QuestionContainer>
                <Accordion sx={{width: '100%'}}>
                    <AccordionSummary
                        expandIcon={
                            questionPanelStatus['q1'] ? <FaMinus style={{color: '#00a0e1'}} /> : <FaPlus style={{color: '#00a0e1'}} />
                        }
                        onClick={()=>{
                            setQuestionPanelStatus({...questionPanelStatus, 'q1': !questionPanelStatus['q1']});
                        }}
                    >
                        <P1 style={{fontWeight: 'bold'}}>
                            How long does the visa application process take?
                        </P1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <P3 style={{color: '#5f5f5f'}}>
                            The timeline varies based on visa type and country, but we provide an estimation 
                            timeframe after assessing your case.
                        </P3>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{width: '100%', marginTop: '20px'}}>
                    <AccordionSummary
                        expandIcon={
                            questionPanelStatus['q2'] ? <FaMinus style={{color: '#00a0e1'}} /> : <FaPlus style={{color: '#00a0e1'}} />
                        }
                        onClick={()=>{
                            setQuestionPanelStatus({...questionPanelStatus, 'q2': !questionPanelStatus['q2']});
                        }}
                    >
                        <P1 style={{fontWeight: 'bold'}}>
                            What documents are required for my visa application?
                        </P1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <P3 style={{color: '#5f5f5f'}}>
                            The required documents for your visa application vary depending on the type of visa and the country you're applying to. 
                            Generally, you will need a valid passport, proof of financial support, educational certificates, language proficiency test results, and any other documents specific to the visa category. 
                            Our counselors will provide you with a detailed checklist based on your unique situation.
                        </P3>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{width: '100%', marginTop: '20px'}}>
                    <AccordionSummary
                        expandIcon={
                            questionPanelStatus['q3'] ? <FaMinus style={{color: '#00a0e1'}} /> : <FaPlus style={{color: '#00a0e1'}} />
                        }
                        onClick={()=>{
                            setQuestionPanelStatus({...questionPanelStatus, 'q3': !questionPanelStatus['q3']});
                        }}
                    >
                        <P1 style={{fontWeight: 'bold'}}>
                            Can you help if my visa application denied?
                        </P1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <P3 style={{color: '#5f5f5f'}}>
                            Yes, if your visa is denied, our experienced counselors can assist you in understanding the reasons for the denial and guide you through the process of reapplying. 
                            We'll help you strengthen your application and address any issues that led to the denial.
                        </P3>
                    </AccordionDetails>
                </Accordion>
                
                <Accordion sx={{width: '100%', marginTop: '20px'}}>
                    <AccordionSummary
                        expandIcon={
                            questionPanelStatus['q4'] ? <FaMinus style={{color: '#00a0e1'}} /> : <FaPlus style={{color: '#00a0e1'}} />
                        }
                        onClick={()=>{
                            setQuestionPanelStatus({...questionPanelStatus, 'q4': !questionPanelStatus['q4']});
                        }}
                    >
                        <P1 style={{fontWeight: 'bold'}}>
                            Do you offer assistance with permanent residency or citizenship?
                        </P1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <P3 style={{color: '#5f5f5f'}}>
                            Yes, we offer comprehensive assistance with permanent residency and citizenship applications. 
                            Our services include evaluating your eligibility, preparing necessary documents, and guiding you through each step of the process to increase your chances of success.
                        </P3>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{width: '100%', marginTop: '20px'}}>
                    <AccordionSummary
                        expandIcon={
                            questionPanelStatus['q5'] ? <FaMinus style={{color: '#00a0e1'}} /> : <FaPlus style={{color: '#00a0e1'}} />
                        }
                        onClick={()=>{
                            setQuestionPanelStatus({...questionPanelStatus, 'q5': !questionPanelStatus['q5']});
                        }}
                    >
                        <P1 style={{fontWeight: 'bold'}}>
                            How do I know which visa is right for me?
                        </P1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <P3 style={{color: '#5f5f5f'}}>
                            Choosing the right visa depends on several factors such as your purpose of travel, duration of stay, and personal circumstances. 
                            Our expert counselors will assess your situation and help you determine the most suitable visa category, ensuring that you apply for the visa that best fits your goals and needs.
                        </P3>
                    </AccordionDetails>
                </Accordion>

            </QuestionContainer>
        </Container>
    )
}

export default Queries;