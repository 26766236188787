import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import Shield from '../../../assets/images/Shield.png';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const ItemContainer = styled(Box)((props) => ({
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: props.marginTop ? `${props.marginTop}px` : '0px',
    width: '100%'
}))

const Item = styled(Box)({
    display: 'flex',
    height: 'fit-content',
    width: '390px'
})

const ItemIconContainer = styled(Box)(({theme}) => ({
    height: '90px',
    width: '90px',
    borderRadius: '50%',
    backgroundColor: '#eef2fe',
    marginRight: '20px',

    [theme.breakpoints.down('sm')]: {
        height: '40px',
        width: '40px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        height: '60px',
        width: '60px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        height: '70px',
        width: '70px'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        height: '90px',
        width: '90px'
    },
    [theme.breakpoints.up('xl')]: {
        height: '90px',
        width: '90px'
    }
}))

const Icon = styled('img')(({theme}) => ({
    height: '90px',
    width: '90px',

    [theme.breakpoints.down('sm')]: {
        height: '40px',
        width: '40px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        height: '60px',
        width: '60px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        height: '70px',
        width: '70px'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        height: '90px',
        width: '90px'
    },
    [theme.breakpoints.up('xl')]: {
        height: '90px',
        width: '90px'
    }
}))

const ItemTextContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
})

const text = [
    {
        title: 'Automated Scheduling',
        line1: 'Intelligent algorithms for',
        line2: 'hassle-free scheduling.'
    },
    {
        title: 'Custimizable Templates',
        line1: 'Save time with reusable and',
        line2: 'personalized templates.'
    },
    {
        title: 'Real-Time Monitoring',
        line1: 'Live proctoring and instant',
        line2: 'analytics for exam integrity.'
    },
    {
        title: 'Grading System',
        line1: 'Automated scoring and',
        line2: 'detailed performance analysis.'
    },
    {
        title: 'Secured Data',
        line1: 'Robust encryption and cloud',
        line2: 'storage for data protection'
    },
    {
        title: 'Reporting and Insights',
        line1: 'Comprehensive reports for',
        line2: 'informed decision-making.'
    },
]

const Features = ({ isMobile }) => {
    return (
        <Container>
            <H2>
                Always Here To Help
            </H2>
            <P2 style={{textAlign: 'center', color: '#5f5f5f', marginTop: '30px'}}>
                "Unlock Endless Possibilities: Comprehensive, User-Friendly Features Tailored to Streamline {isMobile ? <></> : <br />}
                Every Aspect of Your Exam Management Process"
            </P2>

            {
                isMobile ?
                <>
                    <ItemContainer marginTop='60px'>
                    {
                        text.map((textItem, index) => {
                            if(index===0 || index===1) {
                                return (
                                    <Item>
                                        <ItemIconContainer>
                                            <Icon src={Shield} alt='shield_icon' />
                                        </ItemIconContainer>
                                        <ItemTextContainer>
                                            <H5>
                                                {textItem.title}
                                            </H5>
                                            <P2>
                                                {textItem.line1} <br />
                                                {textItem.line2}
                                            </P2>
                                        </ItemTextContainer>
                                    </Item>
                                )
                            }
                            else {
                                return (<></>)
                            }
                        })
                    }
                    </ItemContainer>

                    <ItemContainer marginTop='30px'>
                    {
                        text.map((textItem, index) => {
                            if(index===2 || index===3) {
                                return (
                                    <Item>
                                        <ItemIconContainer>
                                            <Icon src={Shield} alt='shield_icon' />
                                        </ItemIconContainer>
                                        <ItemTextContainer>
                                            <H5>
                                                {textItem.title}
                                            </H5>
                                            <P2>
                                                {textItem.line1} <br />
                                                {textItem.line2}
                                            </P2>
                                        </ItemTextContainer>
                                    </Item>
                                )
                            }
                            else {
                                return (<></>)
                            }
                        })
                    }
                    </ItemContainer>

                    <ItemContainer marginTop='30px'>
                    {
                        text.map((textItem, index) => {
                            if(index===4 || index===5) {
                                return (
                                    <Item>
                                        <ItemIconContainer>
                                            <Icon src={Shield} alt='shield_icon' />
                                        </ItemIconContainer>
                                        <ItemTextContainer>
                                            <H5>
                                                {textItem.title}
                                            </H5>
                                            <P2>
                                                {textItem.line1} <br />
                                                {textItem.line2}
                                            </P2>
                                        </ItemTextContainer>
                                    </Item>
                                )
                            }
                            else {
                                return (<></>)
                            }
                        })
                    }
                    </ItemContainer>
                </>
                :
                <>
                    <ItemContainer marginTop='100px'>
                    {
                        text.map((textItem, index) => {
                            if(index>=0 && index<=2) {
                                return (
                                    <Item>
                                        <ItemIconContainer>
                                            <Icon src={Shield} alt='shield_icon' />
                                        </ItemIconContainer>
                                        <ItemTextContainer>
                                            <H5>
                                                {textItem.title}
                                            </H5>
                                            <P2>
                                                {textItem.line1} <br />
                                                {textItem.line2}
                                            </P2>
                                        </ItemTextContainer>
                                    </Item>
                                )
                            }
                            else {
                                return (<></>)
                            }
                        })
                    }
                    </ItemContainer>

                    <ItemContainer marginTop='30px'>
                    {
                        text.map((textItem, index) => {
                            if(index>=3 && index<=5) {
                                return (
                                    <Item>
                                        <ItemIconContainer>
                                            <Icon src={Shield} alt='shield_icon' />
                                        </ItemIconContainer>
                                        <ItemTextContainer>
                                            <H5>
                                                {textItem.title}
                                            </H5>
                                            <P2>
                                                {textItem.line1} <br />
                                                {textItem.line2}
                                            </P2>
                                        </ItemTextContainer>
                                    </Item>
                                )
                            }
                            else {
                                return (<></>)
                            }
                        })
                    }
                    </ItemContainer>
                </>
            }
        </Container>
    )
}

export default Features;