import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import Shield from '../../../assets/images/Shield.png';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const ItemContainer = styled(Box)((props) => ({
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: props.marginTop ? `${props.marginTop}px` : '0px',
    width: '100%'
}))

const Item = styled(Box)({
    display: 'flex',
    height: 'fit-content',
    width: '390px'
})

const ItemIconContainer = styled(Box)(({theme}) => ({
    height: '90px',
    width: '90px',
    borderRadius: '50%',
    backgroundColor: '#eef2fe',
    marginRight: '20px',

    [theme.breakpoints.down('sm')]: {
        height: '40px',
        width: '40px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        height: '60px',
        width: '60px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        height: '70px',
        width: '70px'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        height: '90px',
        width: '90px'
    },
    [theme.breakpoints.up('xl')]: {
        height: '90px',
        width: '90px'
    }
}))

const Icon = styled('img')(({theme}) => ({
    height: '90px',
    width: '90px',

    [theme.breakpoints.down('sm')]: {
        height: '40px',
        width: '40px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        height: '60px',
        width: '60px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        height: '70px',
        width: '70px'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        height: '90px',
        width: '90px'
    },
    [theme.breakpoints.up('xl')]: {
        height: '90px',
        width: '90px'
    }
}))

const ItemTextContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
})

const text = [
    {
        title: 'Tailored Visa Plans',
        line1: 'Custimized strategies',
        line2: 'for every client.'
    },
    {
        title: 'Full Case Support',
        line1: 'End-to-end assistance',
        line2: 'throughout the process.'
    },
    {
        title: 'Legal Expertise',
        line1: 'Clear guidance on immigration',
        line2: 'laws.'
    },
    {
        title: 'Document Precision',
        line1: 'Accurate preparation and',
        line2: 'review of paperwork.'
    },
    {
        title: 'Appeals Support',
        line1: 'Strong advocacy in case of',
        line2: 'denials.'
    },
    {
        title: 'Settlement Assistance',
        line1: 'Help with post-arrival',
        line2: 'integration.'
    },
]

const Help = ({ isMobile }) => {
    return (
        <Container>
            <H2>
                Always Here To Help
            </H2>
            <P2 style={{textAlign: 'center', color: '#5f5f5f', marginTop: '30px'}}>
                "Unlock Endless Possibilities: Comprehensive, User-Friendly Features Tailored to Streamline {isMobile ? <></> : <br />}
                Every Aspect of Visa Assistance Service"
            </P2>

            {
                isMobile ?
                <>
                    <ItemContainer marginTop='60px'>
                    {
                        text.map((textItem, index) => {
                            if(index===0 || index===1) {
                                return (
                                    <Item>
                                        <ItemIconContainer>
                                            <Icon src={Shield} alt='shield_icon' />
                                        </ItemIconContainer>
                                        <ItemTextContainer>
                                            <H5>
                                                {textItem.title}
                                            </H5>
                                            <P2>
                                                {textItem.line1} <br />
                                                {textItem.line2}
                                            </P2>
                                        </ItemTextContainer>
                                    </Item>
                                )
                            }
                            else {
                                return (<></>)
                            }
                        })
                    }
                    </ItemContainer>

                    <ItemContainer marginTop='30px'>
                    {
                        text.map((textItem, index) => {
                            if(index===2 || index===3) {
                                return (
                                    <Item>
                                        <ItemIconContainer>
                                            <Icon src={Shield} alt='shield_icon' />
                                        </ItemIconContainer>
                                        <ItemTextContainer>
                                            <H5>
                                                {textItem.title}
                                            </H5>
                                            <P2>
                                                {textItem.line1} <br />
                                                {textItem.line2}
                                            </P2>
                                        </ItemTextContainer>
                                    </Item>
                                )
                            }
                            else {
                                return (<></>)
                            }
                        })
                    }
                    </ItemContainer>

                    <ItemContainer marginTop='30px'>
                    {
                        text.map((textItem, index) => {
                            if(index===4 || index===5) {
                                return (
                                    <Item>
                                        <ItemIconContainer>
                                            <Icon src={Shield} alt='shield_icon' />
                                        </ItemIconContainer>
                                        <ItemTextContainer>
                                            <H5>
                                                {textItem.title}
                                            </H5>
                                            <P2>
                                                {textItem.line1} <br />
                                                {textItem.line2}
                                            </P2>
                                        </ItemTextContainer>
                                    </Item>
                                )
                            }
                            else {
                                return (<></>)
                            }
                        })
                    }
                    </ItemContainer>
                </>
                :
                <>
                    <ItemContainer marginTop='100px'>
                    {
                        text.map((textItem, index) => {
                            if(index>=0 && index<=2) {
                                return (
                                    <Item>
                                        <ItemIconContainer>
                                            <Icon src={Shield} alt='shield_icon' />
                                        </ItemIconContainer>
                                        <ItemTextContainer>
                                            <H5>
                                                {textItem.title}
                                            </H5>
                                            <P2>
                                                {textItem.line1} <br />
                                                {textItem.line2}
                                            </P2>
                                        </ItemTextContainer>
                                    </Item>
                                )
                            }
                            else {
                                return (<></>)
                            }
                        })
                    }
                    </ItemContainer>

                    <ItemContainer marginTop='30px'>
                    {
                        text.map((textItem, index) => {
                            if(index>=3 && index<=5) {
                                return (
                                    <Item>
                                        <ItemIconContainer>
                                            <Icon src={Shield} alt='shield_icon' />
                                        </ItemIconContainer>
                                        <ItemTextContainer>
                                            <H5>
                                                {textItem.title}
                                            </H5>
                                            <P2>
                                                {textItem.line1} <br />
                                                {textItem.line2}
                                            </P2>
                                        </ItemTextContainer>
                                    </Item>
                                )
                            }
                            else {
                                return (<></>)
                            }
                        })
                    }
                    </ItemContainer>
                </>
            }
        </Container>
    )
}

export default Help;