import { Paper, styled, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const ServiceIcon = styled('img')(
  ({ theme }) => `
  height: 50px;
  width: 50px;
  margin-bottom: 5px;
  margin-right: 10px
  `
)

const ServiceContainer = (props) => {
  return (
    <Paper
      onClick={props.handleClick}
      variant="outlined"
      sx={{
        borderRadius: '8px',
        borderTopRightRadius: '32px',
        borderBottomLeftRadius: '32px',
        borderColor: '#FFEDBC',
        backgroundColor: 'white',
        minWidth: '360px',
        maxWidth: '360px',
        minHeight: '100px',
        p: 2,
        mr: 2,
        mb: 2,
        cursor: 'pointer'
      }}
    >
      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <ServiceIcon src={props.image} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          <Typography variant='h6'>{props.serviceHeader}</Typography>
          <Typography>{props.serviceBangla}</Typography>
        </Box>
      </Box> */}


      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <ServiceIcon src={props.image} />
        <Typography variant='h6'>{props.serviceHeader}</Typography>
        <Typography>{props.serviceBangla}</Typography>
      </Box>

    </Paper>
  )
}

export default ServiceContainer