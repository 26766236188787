import { Box, Typography, TextField, Button } from '@mui/material';
import { styled } from '@mui/system';
import DemoImage from '../../../assets/images/LMS-Tab.png';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';

const Container = styled(Box)({
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const Image = styled('img')(({theme})=>({
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: '20px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%',
        marginBottom: '20px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '40%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '40%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '40%'
    }
}))

const ContactContainer = styled(Box)(({theme})=>({
    display: 'flex',
    flexDirection: 'column',
    
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '40%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '40%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '40%'
    }
}))

const Demo = () => {
    return (
        <Container>
            <Image src={DemoImage} alt='demo_image' />
            <ContactContainer>
                <H2 style={{color: '#00a0e1', fontWeight: 'bold'}}>
                    Are You <br />
                    looking to use the Demo Version?
                </H2>
                <P2 style={{marginTop: '10px'}}>
                    Fill up the below form and we will get back to you soon!
                </P2>
                
                <P2 style={{marginTop: '10px', color: '#5f5f5f', textAlign: 'justify'}}>
                    Explore the full potential of our platform by trying out our demo
                    version. Experience its robust features, intuitive design, and
                    user-friendly interface firsthand, and see how it can meet your
                    needs.
                </P2>
                
                <TextField label='Name' sx={{marginTop: '50px'}} />
                <TextField label='Mobile Number' sx={{marginTop: '20px'}} />
                <TextField label='E-Mail Address' sx={{marginTop: '20px'}} />
                <Button variant='contained' sx={{marginTop: '20px', width: '200px'}}>
                    <P2>
                        Submit
                    </P2>
                </Button>
            </ContactContainer>
        </Container>
    )
}

export default Demo;