import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const ReasonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    marginTop: '30px',
    width: '100%'
})

const Reason = styled(Box)(({theme})=>({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '23%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '23%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '23%'
    }
}))

const ReasonDetails = styled(P2)(({theme})=>({
    color: '#5f5f5f', 
    marginTop: '30px',
    textAlign: 'justify',

    [theme.breakpoints.down('sm')]: {
        marginTop: '10px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        marginTop: '10px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        marginTop: '30px'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        marginTop: '30px'
    },
    [theme.breakpoints.up('xl')]: {
        marginTop: '30px'
    }
}))


const WhyUs = ({isMobile}) => {
    return (
        <Container>
            <H2 style={{fontWeight: 'bold', color: '#00a0e1'}}>
                Why Choose Our LMS Over Others?
            </H2>
            <ReasonContainer>
                <Reason>
                    <P1 style={{color: '#00a0e1'}}>
                        Trust
                    </P1>
                    <ReasonDetails>
                        Choose our LMS for a trusted, reliable
                        learning experience that puts your
                        success first.
                    </ReasonDetails>
                </Reason>
                <Reason>
                    <P1 style={{color: '#00a0e1', marginTop: isMobile ? '25px' : '0'}}>
                        Integrity
                    </P1>
                    <ReasonDetails variant='h7'>
                        Choose our LMS for unparalleled
                        integrity, ensuring honest and 
                        transparent learning experiences.
                    </ReasonDetails>
                </Reason>
                <Reason>
                    <P1 style={{color: '#00a0e1', marginTop: isMobile ? '25px' : '0'}}>
                        Service
                    </P1>
                    <ReasonDetails variant='h7'>
                        Choose our LMS for exceptional
                        service, providing personalized support
                        every step of your learning journey.
                    </ReasonDetails>
                </Reason>
            </ReasonContainer>
        </Container>
    )
}

export default WhyUs;