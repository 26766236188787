import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import Icon1 from '../../../assets/images/AcademyIcon-1.png';
import Icon2 from '../../../assets/images/AcademyIcon-2.png';
import Icon3 from '../../../assets/images/AcademyIcon-3.png';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: '40vh',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '30px',
    backgroundColor: '#227cd6',
    padding: '0 10px'
})

const TextContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute'
})

const ItemContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'none',

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        top: '95%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        flexDirection: 'column',
        top: '95%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        flexDirection: 'row',
        width: '100%',
        top: '50%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        flexDirection: 'row',
        width: '100%',
        top: '50%'
    },
    [theme.breakpoints.up('xl')]: {
        flexDirection: 'row',
        width: '100%',
        top: '50%'
    }
}))

const Item = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingRight: '10px',

    [theme.breakpoints.down('sm')]: {
        height: 'fit-content',
        width: '300px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        height: 'fit-content',
        width: '300px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        height: '100%',
        width: '450px'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        height: '100%',
        width: '450px'
    },
    [theme.breakpoints.up('xl')]: {
        height: '100%',
        width: '450px'
    }
}))

const EduBriqAcademy = ({ isMobile }) => {
    return (
        <Container>
            <TextContainer>
                <H2 style={{color: 'white'}}>
                    EduBriq Academy
                </H2>
                <P2 style={{color: 'white', textAlign: 'center'}}>
                    Elevate Your Potential with Targeted Training & Personalized Courses Designed for {isMobile ? <></> : <br />}
                    Your Career Success
                </P2>
            </TextContainer>
            <ItemContainer>
                <Item>
                    <img src={Icon1} alt='academy_icon1' style={{width: '150px'}}/>
                    <Box>
                        <H5>
                            Online Courses
                        </H5>
                        <P3 style={{marginTop: isMobile ? '10px' : '20px', color: '#5f5f5f', textAlign: 'justify'}}>
                            Choose form hundreds of free
                            training courses, or pay to get
                            certified to complete a course or
                            specialization.
                        </P3>
                    </Box>
                </Item>

                <Item>
                    <img src={Icon2} alt='academy_icon2' style={{width: '150px'}} />
                    <Box>
                        <H5>
                            Course Certificate
                        </H5>
                        <P3 style={{marginTop: isMobile ? '10px' : '20px', color: '#5f5f5f', textAlign: 'justify'}}>
                            Join the millions of students
                            around the world who are already
                            learning! Find an experienced and 
                            suitable instructor only for you.
                        </P3>
                    </Box>
                </Item>

                <Item>
                    <img src={Icon3} alt='academy_icon3' style={{width: '150px'}} />
                    <Box>
                        <H5>
                            Job Placement
                        </H5>
                        <P3 style={{marginTop: isMobile ? '10px' : '20px', color: '#5f5f5f', textAlign: 'justify'}}>
                            We'll help your craft a standout
                            resume, refine your interview skills,
                            and secure a job with an annual 
                            salary of $80K or more.
                        </P3>
                    </Box>
                </Item>
            </ItemContainer>
        </Container>
    )
}

export default EduBriqAcademy;