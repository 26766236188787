import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import JobSupportBanner2 from '../../../assets/images/Job-support-banner2.png';
import colors from '../../../utility/styles';

const Container = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const TextContainer = styled(Box)(({theme})=>({
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: '30px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%',
        marginTop: '30px',
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '50%',
        marginLeft: '50px'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '50%',
        marginLeft: '50px'
    },
    [theme.breakpoints.up('xl')]: {
        width: '50%',
        marginLeft: '50px'
    }
}))

const ExclusiveFeatures = ({ isMobile }) => {
    return (
        <Container>
            <img src={JobSupportBanner2} alt='image' style={{width: isMobile ? '100%' : '30%'}}/>
            <TextContainer>
                <H2>
                    Exclusive Features <br />
                    & Services
                </H2>
                <P2 style={{marginTop: '20px', color: '#5f5f5f', textAlign: 'justify'}}>
                    The job support portal is a comprehensive
                    platform designed to assist job seekers with
                    resources, guidance and opportunities to 
                    enhance their career prospects

                    <ul style={{marginTop: '20px', marginLeft: isMobile ? '10px' : '0px'}}>
                        <li className='point'>Create standout profile submission</li>
                        <li className='point'>Find tailored job openings at your desired location</li>
                        <li className='point'>Get expert job search advice with premium accounts</li>
                    </ul>
                </P2>

                <Button variant='contained' color='error' sx={{marginTop: '50px'}}>
                    <P2>
                        Get Started
                    </P2>
                </Button>

            </TextContainer>
        </Container>
    )
}

export default ExclusiveFeatures;