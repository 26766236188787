import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '100px',
    width: '100%',
    maxWidth: '1920px',
    padding: '0 10px'
})

const ContactUsContainer = styled(P2)({
    color: '#5f5f5f', 
    marginTop: '10px',
    textAlign: 'center'
})

const QuestionContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    align: 'center',
    width: '100%',
    maxWidth: '1000px',
    marginTop: '30px'
})

const Queries = () => {
    const [questionPanelStatus, setQuestionPanelStatus] = useState({'q1': false, 'q2': false, 'q3': false, 'q4': false, 'q5': false});

    return (
        <Container>
            <H2 style={{fontWeight: 'bold'}}>
                Frequently Asked Queries
            </H2>
            <ContactUsContainer variant='h6'> 
                Let's answer few of your questions. <br/>
                Still have thoughts? <Link to='/contact' style={{textDecoration: 'none'}}><span style={{color: '#00a0e1'}}>Contact us</span></Link>
            </ContactUsContainer>
            <QuestionContainer>
                <Accordion sx={{width: '100%'}}>
                    <AccordionSummary
                        expandIcon={
                            questionPanelStatus['q1'] ? <FaMinus style={{color: '#00a0e1'}} /> : <FaPlus style={{color: '#00a0e1'}} />
                        }
                        onClick={()=>{
                            setQuestionPanelStatus({...questionPanelStatus, 'q1': !questionPanelStatus['q1']});
                        }}
                    >
                        <P1 style={{fontWeight: 'bold'}}>
                            How does the automated exam scheduling work?
                        </P1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <P3 style={{color: '#5f5f5f'}}>
                            Our platform uses advanced algorithms to automatically schedule exams
                            based on teacher availability and student timetables, ensuring optimal 
                            timing and minimizing conflicts.
                        </P3>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{width: '100%', marginTop: '20px'}}>
                    <AccordionSummary
                        expandIcon={
                            questionPanelStatus['q2'] ? <FaMinus style={{color: '#00a0e1'}} /> : <FaPlus style={{color: '#00a0e1'}} />
                        }
                        onClick={()=>{
                            setQuestionPanelStatus({...questionPanelStatus, 'q2': !questionPanelStatus['q2']});
                        }}
                    >
                        <P1 style={{fontWeight: 'bold'}}>
                            Can I customize the exam templates to fit my specific needs?
                        </P1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <P3 style={{color: '#5f5f5f'}}>
                            Yes, our platform allows full customization of exams. You can tailor the exam format, question types, time limits, and grading criteria to meet your specific requirements. 
                            Whether you need a multiple-choice quiz or a comprehensive written exam, our system adapts to your needs.
                        </P3>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{width: '100%', marginTop: '20px'}}>
                    <AccordionSummary
                        expandIcon={
                            questionPanelStatus['q3'] ? <FaMinus style={{color: '#00a0e1'}} /> : <FaPlus style={{color: '#00a0e1'}} />
                        }
                        onClick={()=>{
                            setQuestionPanelStatus({...questionPanelStatus, 'q3': !questionPanelStatus['q3']});
                        }}
                    >
                        <P1 style={{fontWeight: 'bold'}}>
                            What measures are in place to ensure exam security?
                        </P1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <P3 style={{color: '#5f5f5f'}}>
                            We prioritize exam security through multiple layers of protection. 
                            Our platform uses encryption to safeguard data, proctoring tools to monitor exam sessions, and secure login methods to verify user identity. 
                            Additionally, question randomization and time controls help prevent cheating and unauthorized access.
                        </P3>
                    </AccordionDetails>
                </Accordion>
                
                <Accordion sx={{width: '100%', marginTop: '20px'}}>
                    <AccordionSummary
                        expandIcon={
                            questionPanelStatus['q4'] ? <FaMinus style={{color: '#00a0e1'}} /> : <FaPlus style={{color: '#00a0e1'}} />
                        }
                        onClick={()=>{
                            setQuestionPanelStatus({...questionPanelStatus, 'q4': !questionPanelStatus['q4']});
                        }}
                    >
                        <P1 style={{fontWeight: 'bold'}}>
                            How does the grading system work?
                        </P1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <P3 style={{color: '#5f5f5f'}}>
                            Our grading system is designed to be both flexible and accurate. 
                            You can set custom grading scales, weight different sections of the exam, and choose between automatic or manual grading options. 
                            Results are calculated instantly and can be reviewed or adjusted as needed before finalizing the grades.
                        </P3>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{width: '100%', marginTop: '20px'}}>
                    <AccordionSummary
                        expandIcon={
                            questionPanelStatus['q5'] ? <FaMinus style={{color: '#00a0e1'}} /> : <FaPlus style={{color: '#00a0e1'}} />
                        }
                        onClick={()=>{
                            setQuestionPanelStatus({...questionPanelStatus, 'q5': !questionPanelStatus['q5']});
                        }}
                    >
                        <P1 style={{fontWeight: 'bold'}}>
                            Is my data secure on your platform?
                        </P1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <P3 style={{color: '#5f5f5f'}}>
                        Absolutely. We implement stringent data security protocols to protect your information. 
                        This includes end-to-end encryption, regular security audits, and compliance with data protection regulations. 
                        Your data is stored securely on our servers and is only accessible to authorized users.
                        </P3>
                    </AccordionDetails>
                </Accordion>

            </QuestionContainer>
        </Container>
    )
}

export default Queries;