import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import WhyUsIcon1 from '../../../assets/images/Why-us-icon1.png';
import WhyUsIcon2 from '../../../assets/images/Why-us-icon2.png';
import WhyUsIcon3 from '../../../assets/images/Why-us-icon3.png';
import WhyUsIcon4 from '../../../assets/images/Why-us-icon4.png';
import WhyUsIcon5 from '../../../assets/images/Why-us-icon5.png';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const ItemContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%'
})

const Item = styled(Box)(({theme})=>({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
        width: '50%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '50%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '15%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '15%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '15%'
    }
}))

const Image = styled('img')(({theme})=>({
    width: '50%',
    marginBottom: '15px'
}))

const WhyUs = ({ isMobile }) => {
    return (
        <Container>
            <H2 style={{fontWeight: 'bold', marginBottom: '50px'}}>
                Why Choose Us!
            </H2>
            <ItemContainer>
                <Item>
                    <Image src={WhyUsIcon1} alt='icon_1' />
                    <P2 style={{fontWeight: 'bold', textAlign: 'center'}}>
                        Easy Tutor <br />
                        Matching
                    </P2>
                </Item>

                <Item>
                    <Image src={WhyUsIcon2} alt='icon_2' />
                    <P2 style={{fontWeight: 'bold', textAlign: 'center'}}>
                        Flexible <br />
                        Scheduling
                    </P2>
                </Item>

                <Item style={{marginTop: isMobile ? '20px' : '0'}}>
                    <Image src={WhyUsIcon3} alt='icon_3' />
                    <P2 style={{fontWeight: 'bold', textAlign: 'center'}}>
                        Performance <br />
                        Tracking
                    </P2>
                </Item>

                <Item style={{marginTop: isMobile ? '20px' : '0'}}>
                    <Image src={WhyUsIcon4} alt='icon_4' />
                    <P2 style={{fontWeight: 'bold', textAlign: 'center'}}>
                        Dedicated <br />
                        Support
                    </P2>
                </Item>

                <Item style={{marginTop: isMobile ? '20px' : '0'}}>
                    <Image src={WhyUsIcon5} alt='icon_5' />
                    <P2 style={{fontWeight: 'bold', textAlign: 'center'}}>
                        24/7 <br />
                        Availability
                    </P2>
                </Item>
            </ItemContainer>
        </Container>
    )
}

export default WhyUs;