import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import Colors from '../../../utility/styles.js';
import ServiceImage1 from '../../../assets/images/Aboutus-service1.png';
import ServiceImage2 from '../../../assets/images/Aboutus-service2.png';
import ServiceImage3 from '../../../assets/images/Aboutus-service3.png';
import ServiceImage4 from '../../../assets/images/Aboutus-service4.png';

const Container = styled(Box)(({theme})=>({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    backgroundColor: Colors.secondaryLightBlue,
    padding: '5%',

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
    [theme.breakpoints.between('sm', 'md')]: {
        flexDirection: 'column',
    },
    [theme.breakpoints.between('md', 'lg')]: {
        flexDirection: 'row',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        flexDirection: 'row',
    },
    [theme.breakpoints.up('xl')]: {
        flexDirection: 'row',
    }
}))

const ImageContainer = styled(Box)(({theme})=>({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',

    [theme.breakpoints.down('sm')]: {
        width: '100%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '50%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '50%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '50%'
    }
}))

const ImageRow = styled(Box)({
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%'
})

const Image = styled('img')(({theme})=>({
    height: 'auto',

    [theme.breakpoints.down('sm')]: {
        width: '45%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '45%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '40%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '40%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '40%'
    }
}))

const TextContainer = styled(Box)(({theme})=>({
    [theme.breakpoints.down('sm')]: {
        marginTop: '5%',
        width: '100%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        marginTop: '5%',
        width: '100%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '40%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '40%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '40%'
    }
}))

const Services = ({ isMobile }) => {
    return (
        <Container>
            <ImageContainer>
                <ImageRow>
                    <Image src={ServiceImage1} alt='image1' />
                    <Image src={ServiceImage2} alt='image2' style={{marginLeft: isMobile ? '5%' : ''}} />
                </ImageRow>
                <ImageRow sx={{marginTop: '5%'}}>
                    <Image src={ServiceImage3} alt='image3' />
                    <Image src={ServiceImage4} alt='image4' style={{marginLeft: isMobile ? '5%' : ''}} />
                </ImageRow>
            </ImageContainer>
            <TextContainer>
                <H2>
                    Services Tailored To You!
                </H2>
                <P2 style={{marginTop: '5%', textAlign: 'justify', color: '#5f5f5f'}}>
                    Our comprehensive services are designed to meet your unique
                    needs and aspirations, whether your a student navigating your
                    academic path or a professional seeking to enhance your skill
                    set. From student counseling to tutoring and specialized 
                    training, we provide personalized support every step of the way.
                    Our advanced learning platforms leverage technology to make
                    education accessible and engaging, while our online exam 
                    proctoring ensures academic integrity. Join us as we embark
                    together on the journey to excellence.
                </P2>
            </TextContainer>
        </Container>
    )
}

export default Services;