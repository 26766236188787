import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../utility/Typography';

const Container = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '60px',
    width: '100%'   
})

const BrochureButton = styled(Button)({
    backgroundColor: 'orange',
    marginRight: '50px',
    ':hover': {
        backgroundColor: 'orange'
    }
})

const Brochure = () => {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            {isMobile ?
            <></>
            :
            <Container>
                <BrochureButton variant='contained'>
                    <P3>Download Brochure</P3>
                </BrochureButton>
            </Container>
            }
        </>
    )
}

export default Brochure;