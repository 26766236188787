import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

import BannerImage1 from '../../../assets/images/Home-banner1.png';

const Container = styled(Box)(({theme})=>({
    width: '100%',
    maxWidth: '1920px',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
        top: '0'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        top: '0'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        top: '-170px',
        zIndex: '-1',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        top: '-170px',
        zIndex: '-1',
    },
    [theme.breakpoints.up('xl')]: {
        top: '-170px',
        zIndex: '-1',
    }
}))

const Banner = ({ isMobile }) => {
    return (
        <Container>
            <img src={BannerImage1} alt='image' style={{width: '100%'}} />
        </Container>
    )
}

export default Banner;