import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import AuthorIcon from '../../../assets/images/Review-Author.png';
import StarIcon from '../../../assets/images/Star.png';
import Colors from '../../../utility/styles';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    padding: '20px',
    boxShadow: `0px 7px 7px ${Colors.lightGrey}`
})

const ReviewAuthor = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '20px'
})

const AuthorDetails = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '30px'
})

const StarRating = styled(Box)({
    display: 'flex'
})

const ReviewCard = (props) => {
    const starList = [];
    for(let i=0; i<props.stars; ++i) {
        starList.push(<img src={StarIcon} alt='star_icon' style={{height: '30px', width: '30px'}}/>);
    }

    return (
        <Container>
            <P2 style={{color: '#5f5f5f'}}>
                {props.reviewText}
            </P2>
            <ReviewAuthor>
                <img src={AuthorIcon} alt='author_icon' />
                <AuthorDetails>
                    <P2>
                        {props.reviewName} 
                    </P2>
                    <P2 style={{color: '#5f5f5f'}}>
                        {props.profession}
                    </P2>
                </AuthorDetails>
            </ReviewAuthor>
            <StarRating>
                {
                    starList.map((star) => {
                        return star;
                    })
                }
            </StarRating>
        </Container>
    )
}

export default ReviewCard;