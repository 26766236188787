import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import Industry1 from '../../../assets/images/LMS-Icon-1.png';
import Industry2 from '../../../assets/images/LMS-Icon-2.png';
import Industry3 from '../../../assets/images/LMS-Icon-3.png';
import Industry4 from '../../../assets/images/LMS-Icon-4.png';
import Industry5 from '../../../assets/images/LMS-Icon-5.png';
import Industry6 from '../../../assets/images/LMS-Icon-6.png';


const Container = styled(Box)({
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const IndustryContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    width: '100%'
})

const Industry = styled(Box)(({theme})=>({
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: '20px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%',
        marginTop: '20px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '27%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '27%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '27%'
    }
}))

const IndustryIcon = styled(Box)(({theme})=>({
    height: 'auto',
    width: '20%',

    [theme.breakpoints.down('sm')]: {
        width: '20%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '10%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '20%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '20%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '20%'
    }
}))

const IndustryDetails = styled(Box)(({theme})=>({
    [theme.breakpoints.down('sm')]: {
        width: '80%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '90%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '80%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '80%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '80%'
    }
}))

const Industries = ({ isMobile }) => {
    return (
        <Container>
            <H2 style={{fontWeight: 'bold', color: '#00a0e1'}}>
                Industries We Serve
            </H2>
            <Box sx={{width: '100%', marginTop: '30px'}}>
                <IndustryContainer>
                    <Industry>
                        <IndustryIcon> 
                            <img src={Industry1} alt='industry_1' style={{width: '100%'}} />
                        </IndustryIcon>
                        <IndustryDetails>
                            <P1 style={{color: '#00a0e1', marginBottom: '10px'}}>
                                EDUCATION
                            </P1>
                            <P2 style={{textAlign: 'justify', color: '#5f5f5f'}}>
                                LMS platforms are vital for managing 
                                online courses and facilitating 
                                communication in schools and 
                                universities
                            </P2>    
                        </IndustryDetails>    
                    </Industry>

                    <Industry>
                        <IndustryIcon> 
                            <img src={Industry2} alt='industry_2' style={{width: '100%'}} />
                        </IndustryIcon>
                        <IndustryDetails>
                            <P1 style={{color: '#00a0e1', marginBottom: '10px'}}>
                                CORPORATE TRAINING
                            </P1>
                            <P2 style={{textAlign: 'justify', color: '#5f5f5f'}}>
                                Businesses use LMS for employee
                                onboarding, skill development
                                compliance training, and performance
                                management.    
                            </P2>    
                        </IndustryDetails>    
                    </Industry>

                    <Industry>
                        <IndustryIcon> 
                            <img src={Industry3} alt='industry_3' style={{width: '100%'}} />
                        </IndustryIcon>
                        <IndustryDetails>
                            <P1 style={{color: '#00a0e1', marginBottom: '10px'}}>
                                HEALTHCARE
                            </P1>
                            <P2 style={{textAlign: 'justify', color: '#5f5f5f'}}>
                                LMS trains medical professionals,
                                provides continuing education, and
                                ensures compliance with healthcare 
                                regulations and standards    
                            </P2>    
                        </IndustryDetails>    
                    </Industry>
                </IndustryContainer>

                <IndustryContainer sx={{marginTop: isMobile ? '0px' : '30px'}}>
                    <Industry>
                        <IndustryIcon> 
                            <img src={Industry4} alt='industry_4' style={{width: '100%'}} />
                        </IndustryIcon>
                        <IndustryDetails>
                            <P1 style={{color: '#00a0e1', marginBottom: '10px'}}>
                                MANUFACTURING
                            </P1>
                            <P2 style={{textAlign: 'justify', color: '#5f5f5f'}}>
                                LMS trains employees on safety
                                protocols, operational procedures,
                                and new technologies to maintain
                                productivity and safety standards.
                            </P2>    
                        </IndustryDetails>    
                    </Industry>

                    <Industry>
                        <IndustryIcon> 
                            <img src={Industry5} alt='industry_5' style={{width: '100%'}} />
                        </IndustryIcon>
                        <IndustryDetails>
                            <P1 style={{color: '#00a0e1', marginBottom: '10px'}}>
                                GOVERNMENT
                            </P1>
                            <P2 style={{textAlign: 'justify', color: '#5f5f5f'}}>
                                Government agencies use LMS for
                                employee training on regulations,
                                policies and essetial skills to ensure
                                compliance and efficient public
                                service.    
                            </P2>    
                        </IndustryDetails>    
                    </Industry>

                    <Industry>
                        <IndustryIcon> 
                            <img src={Industry6} alt='industry_6' style={{width: '100%'}} />
                        </IndustryIcon>
                        <IndustryDetails>
                            <P1 style={{color: '#00a0e1', marginBottom: '10px'}}>
                                RETAIL
                            </P1>
                            <P2 style={{textAlign: 'justify', color: '#5f5f5f'}}>
                                Retails companies use LMS for staff
                                training on customer service, product
                                knowledge, sales techniques and 
                                compliance, boosting satisfaction and
                                sales.     
                            </P2>    
                        </IndustryDetails>    
                    </Industry>
                </IndustryContainer>
            </Box>
        </Container>
    )
}

export default Industries;