import { Box, Typography, TextField, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";
import { GoShieldCheck } from "react-icons/go";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import TutorIcon1 from '../../../assets/images/Tutor-icon1.png';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    backgroundColor: '#a2e2fb',
    padding: '50px 10px 10px 10px'
})

const SearchBoxContainer = styled(Box)(({theme})=>({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80px',
    width: '55%',
    marginTop: '30px',
    backgroundColor: 'white',
    borderRadius: '10px 10px 10px 10px',

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '55%',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '55%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '55%'
    }
}))

const SearchTopicContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '55%',
    marginTop: '50px'
})

const SearchText = styled(Box)(({theme})=>({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
        width: '100%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: 'fit-content',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: 'fit-content'
    },
    [theme.breakpoints.up('xl')]: {
        width: 'fit-content'
    }
}))

const SearchItems = styled(Box)(({theme})=>({
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
        width: '40%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '40%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: 'fit-content',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: 'fit-content'
    },
    [theme.breakpoints.up('xl')]: {
        width: 'fit-content'
    }
}))

const Icon = styled('img')({
    width: '20px'
})

const FindTutor = ({ isMobile }) => {
    return (
        <Container>
            <H2 style={{color: '#5f5f5f', fontWeight: 'bold'}}>
                Find A Tutor 
            </H2>
            <P3 style={{color: '#5f5f5f'}}>
                Choose Your Desired Tutor From Different Segment
            </P3>

            <SearchBoxContainer>
                <TextField placeholder="Enter your desired Teacher's name/segment here!" sx={{width: '75%'}} />
                <Button variant='contained' sx={{height: '50px', marginLeft: '20px'}}>
                    <FaSearch size='30px' style={{marginRight: '10px'}} />
                    <P2>
                        Search
                    </P2>
                </Button>
            </SearchBoxContainer>

            <SearchTopicContainer>
                <SearchText>
                    <P3 style={{color: '#5f5f5f'}}>
                        Searches
                    </P3>
                </SearchText>
                <SearchItems sx={{marginTop: isMobile ? '10px' : '0'}}>
                    <Icon src={TutorIcon1} alt='icon' />
                    <P3 style={{color: '#5f5f5f', marginLeft: '10px'}}>
                        Science
                    </P3>
                </SearchItems>
                <SearchItems sx={{marginTop: isMobile ? '10px' : '0'}}>
                    <Icon src={TutorIcon1} alt='icon' />
                    <P3 style={{color: '#5f5f5f', marginLeft: '10px'}}>
                        Math
                    </P3>
                </SearchItems>
                <SearchItems sx={{marginTop: isMobile ? '10px' : '0'}}>
                    <Icon src={TutorIcon1} alt='icon' />
                    <P3 style={{color: '#5f5f5f', marginLeft: '10px'}}>
                        Arts & History
                    </P3>
                </SearchItems>
                <SearchItems sx={{marginTop: isMobile ? '10px' : '0'}}>
                    <Icon src={TutorIcon1} alt='icon' />
                    <P3 style={{color: '#5f5f5f', marginLeft: '10px'}}>
                        Others
                    </P3>
                </SearchItems>
            </SearchTopicContainer>

            <Box sx={{marginTop: '50px'}}>
                <H2 style={{fontWeight: 'bold', color: '#5f5f5f', textAlign: 'center'}}>
                    Need a suggestion? {isMobile ? <br /> : <></>} <Link to='/contact' style={{textDecorationColor: '#00a0e1'}}><span style={{color: '#00a0e1'}}>Contact Us!</span></Link>
                </H2>
            </Box>
        </Container>
    )
}

export default FindTutor;