import { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import CompanyIcon1 from '../../../assets/images/Job-support-company1.png';
import CompanyIcon2 from '../../../assets/images/Job-support-company2.png';
import Colors from '../../../utility/styles';
import JobCard from './JobCard.js';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const JobContainer = styled(Box)(({theme})=>({
    display: 'flex',
    width: '100%',
    marginTop: '30px',

    [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        justifyContent: 'center'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        justifyContent: 'space-evenly'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        justifyContent: 'space-evenly'
    },
    [theme.breakpoints.up('xl')]: {
        justifyContent: 'space-evenly'
    }
}))

const LeftControl = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    width: '50px',
    borderRadius: '50%',

    ':hover': {
        backgroundColor: `${Colors.lightBlue}`
    },
    ':active': {
        backgroundColor: `${Colors.lightBlue}`
    }
})

const RightControl = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    width: '50px',
    borderRadius: '50%',

    ':hover': {
        backgroundColor: `${Colors.lightBlue}`
    },
    ':active': {
        backgroundColor: `${Colors.lightBlue}`
    }
})

const ControlBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-around',
    width: '200px',
    marginTop: '30px'
})

const LeftIcon = styled(SlArrowLeft)({
    fontSize: '20px',
})

const RightIcon = styled(SlArrowRight)({
    fontSize: '20px',
})

const jobList = [
    {
        companyIcon: CompanyIcon1,
        companyName: 'Trip Advisor',
        jobCategory: 'Business Development',
        location: 'Sydney',
        jobType: 'Full Time',
        salary: '$80K'
    },
    {
        companyIcon: CompanyIcon2,
        companyName: 'Pinterest',
        jobCategory: 'Business Development',
        location: 'Melbourne',
        jobType: 'Full Time',
        salary: '$120K'
    },
    {
        companyIcon: CompanyIcon1,
        companyName: 'Trip Advisor',
        jobCategory: 'Business Development',
        location: 'Sydney',
        jobType: 'Full Time',
        salary: '$80K'
    },
    {
        companyIcon: CompanyIcon1,
        companyName: 'Trip Advisor',
        jobCategory: 'Business Development',
        location: 'Sydney',
        jobType: 'Full Time',
        salary: '$80K'
    },
    {
        companyIcon: CompanyIcon2,
        companyName: 'Pinterest',
        jobCategory: 'Business Development',
        location: 'Melbourne',
        jobType: 'Full Time',
        salary: '$120K'
    },
    {
        companyIcon: CompanyIcon1,
        companyName: 'Trip Advisor',
        jobCategory: 'Business Development',
        location: 'Sydney',
        jobType: 'Full Time',
        salary: '$80K'
    }
]

const FeaturedJobs = ({ isMobile }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    let jobListLastIndex = jobList.length - 1;
    
    const handleRightArrowClick = () => {
        if(currentIndex+1 <= jobListLastIndex) {
            setCurrentIndex(currentIndex+1);
        }
    }

    const handleLeftArrowClick = () => {
        if(currentIndex-1 >= 0) {
            setCurrentIndex(currentIndex-1);
        }
    }

    return (
        <Container>
            <H2>Most Demanding Job Categories</H2>
            <P2 style={{color: '#5f5f5f', textAlign: 'center', marginTop: '20px', width: isMobile ? '100%' : '70%'}}>
                The Job Support Portal is a comprehensive platform designed to assist job seekers
                with resources, guidance, and 
                opportunities to enhance their career prospects 
            </P2>
            {isMobile ?
                <ControlBox>
                    <LeftControl onClick={handleLeftArrowClick}>
                        <LeftIcon />
                    </LeftControl>
                    <RightControl onClick={handleRightArrowClick}>
                        <RightIcon />
                    </RightControl>
                </ControlBox>
                :
                <></>
            }
            {isMobile ?
            <>
                <JobContainer>
                    <JobCard
                        CompanyIcon={jobList[currentIndex].companyIcon}
                        CompanyName={jobList[currentIndex].companyName}
                        JobCategory={jobList[currentIndex].jobCategory}
                        Location={jobList[currentIndex].location}
                        JobType={jobList[currentIndex].jobType}
                        Salary={jobList[currentIndex].salary}
                    />
                </JobContainer>
            </>
            :
            <>
                <JobContainer>
                    <JobCard
                        CompanyIcon={jobList[0].companyIcon}
                        CompanyName={jobList[0].companyName}
                        JobCategory={jobList[0].jobCategory}
                        Location={jobList[0].location}
                        JobType={jobList[0].jobType}
                        Salary={jobList[0].salary}
                    />
                    <JobCard
                        CompanyIcon={jobList[1].companyIcon}
                        CompanyName={jobList[1].companyName}
                        JobCategory={jobList[1].jobCategory}
                        Location={jobList[1].location}
                        JobType={jobList[1].jobType}
                        Salary={jobList[1].salary}
                    />
                    <JobCard
                        CompanyIcon={jobList[2].companyIcon}
                        CompanyName={jobList[2].companyName}
                        JobCategory={jobList[2].jobCategory}
                        Location={jobList[2].location}
                        JobType={jobList[2].jobType}
                        Salary={jobList[2].salary}
                    />
                </JobContainer>

                <JobContainer sx={{marginTop: '30px'}}>
                    <JobCard
                        CompanyIcon={jobList[3].companyIcon}
                        CompanyName={jobList[3].companyName}
                        JobCategory={jobList[3].jobCategory}
                        Location={jobList[3].location}
                        JobType={jobList[3].jobType}
                        Salary={jobList[3].salary}
                    />
                    <JobCard
                        CompanyIcon={jobList[4].companyIcon}
                        CompanyName={jobList[4].companyName}
                        JobCategory={jobList[4].jobCategory}
                        Location={jobList[4].location}
                        JobType={jobList[4].jobType}
                        Salary={jobList[4].salary}
                    />
                    <JobCard
                        CompanyIcon={jobList[5].companyIcon}
                        CompanyName={jobList[5].companyName}
                        JobCategory={jobList[5].jobCategory}
                        Location={jobList[5].location}
                        JobType={jobList[5].jobType}
                        Salary={jobList[5].salary}
                    />
                </JobContainer>
            </>
            }
        </Container>
    )
}

export default FeaturedJobs;