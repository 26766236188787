import { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import ReviewCard from './ReviewCard';
import Colors from '../../../utility/styles';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const TitleContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        flexDirection: 'column',
        alignItems: 'center'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        flexDirection: 'row'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        flexDirection: 'row'
    },
    [theme.breakpoints.up('xl')]: {
        flexDirection: 'row'
    }
}))

const ControlBox = styled(Box)(({theme})=>({
    display: 'flex',
    justifyContent: 'space-around',
    
    [theme.breakpoints.down('sm')]: {
        width: '50%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '50%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '15%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '15%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '15%'
    }
}))

const LeftControl = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    width: '50px',
    borderRadius: '50%',

    ':hover': {
        backgroundColor: `${Colors.lightBlue}`
    },
    ':active': {
        backgroundColor: `${Colors.lightBlue}`
    }
})

const RightControl = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    width: '50px',
    borderRadius: '50%',

    ':hover': {
        backgroundColor: `${Colors.lightBlue}`
    },
    ':active': {
        backgroundColor: `${Colors.lightBlue}`
    }
})

const LeftIcon = styled(SlArrowLeft)({
    fontSize: '20px',
})

const RightIcon = styled(SlArrowRight)({
    fontSize: '20px',
})

const ReviewCardContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    marginTop: '50px'
})


const reviewList = [
    {
        reviewText: '"EduBriq delivers on it\'s promise! Career guidance was spot on, tutoring top-notch and the advanced tools made learning a breeze. Highly recommended!"',
        reviewName: 'Hasan Mahmud',
        profession: 'Student',
        stars: 5
    },
    {
        reviewText: '"EduBriq\'s support was exceptional! Counseling clarified my path, personalized guidance boosted my confidence, and the advanced tools enhanced my learning. Ultimate partner for success!"',
        reviewName: 'Adib Mohammed',
        profession: 'Job Holder',
        stars: 4
    },
    {
        reviewText: '3"EduBriq\'s support was exceptional! Counseling clarified my path, personalized guidance boosted my confidence, and the advanced tools enhanced my learning. Ultimate partner for success!"',
        reviewName: 'Adib Mohammed',
        profession: 'Student',
        stars: 3
    },
    {
        reviewText: '4"EduBriq\'s support was exceptional! Counseling clarified my path, personalized guidance boosted my confidence, and the advanced tools enhanced my learning. Ultimate partner for success!"',
        reviewName: 'Adib Mohammed',
        profession: 'Job Holder',
        stars: 2
    }
]

const Review = ({ isMobile }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    let reviewLastIndex = reviewList.length - 1;
    let offset = isMobile ? 1 : 2;
    
    useEffect(()=>{
        if(isMobile===false && currentIndex%2===1) {
            setCurrentIndex(currentIndex-1);
        }
    }, [isMobile]);

    const handleRightArrowClick = () => {
        if(currentIndex+offset <= reviewLastIndex) {
            setCurrentIndex(currentIndex+offset);
        }
    }

    const handleLeftArrowClick = () => {
        if(currentIndex-offset >= 0) {
            setCurrentIndex(currentIndex-offset);
        }
    }

    return (
        <Container>
            <TitleContainer>
                <H2 style={{width: isMobile ? '100%' : '65%'}}>
                    What People Say About Us!
                </H2>
                <ControlBox>
                    <LeftControl onClick={handleLeftArrowClick}>
                        <LeftIcon />
                    </LeftControl>
                    <RightControl onClick={handleRightArrowClick}>
                        <RightIcon />
                    </RightControl>
                </ControlBox>
            </TitleContainer>
            <ReviewCardContainer>
                {
                    <ReviewCard 
                        reviewText={reviewList[currentIndex].reviewText}
                        reviewName={reviewList[currentIndex].reviewName}
                        profession={reviewList[currentIndex].profession}
                        stars={reviewList[currentIndex].stars}
                    />
                }
                {    
                    (isMobile===false && currentIndex+1 <= reviewLastIndex) ?
                    <ReviewCard 
                        reviewText={reviewList[currentIndex+1].reviewText}
                        reviewName={reviewList[currentIndex+1].reviewName}
                        profession={reviewList[currentIndex+1].profession}
                        stars={reviewList[currentIndex+1].stars}
                    />
                    :
                    <></>  
                }
            </ReviewCardContainer>
        </Container>
    )
}

export default Review;