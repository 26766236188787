import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import Icon from '../../../images/Icon.png';

const StyledCard = styled(Card)(({ theme }) => ({
    backgroundColor: 'white',
    minWidth: 275,
    margin: theme.spacing(2),
    textAlign: 'center',
    position: 'relative',
}));

const HighlightCard = styled(Card)(({ theme }) => ({
    backgroundColor: '#FFF9C4', // Light yellow background for the highlighted card
    minWidth: 275,
    margin: theme.spacing(2),
    textAlign: 'center',
    position: 'relative',
}));

const FeatureCard = ({ title, description, highlighted }) => {
    const CardComponent = highlighted ? HighlightCard : StyledCard;
    return (
        <CardComponent>
            <CardContent
                sx={{
                    minHeight: 150,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <img src={Icon} alt={title} width={50} height={50} />
                </Box>
                <Typography variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
        </CardComponent>
    )
}

export default FeatureCard