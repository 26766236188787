import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { GoClock } from "react-icons/go";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import LocationIcon from '../../../assets/images/Job-support-location.png';
import StarIcon from '../../../assets/images/Star.png';
import colors from '../../../utility/styles';

const Card = styled(Box)((props)=>({
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center',
    width: '300px',
    height: '400px',
    padding: '10px',
    border: '1px solid black',
    borderRadius: '5px'
}))

const ExperienceBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '70px'
})

const DetailButton = styled(Button)({
    backgroundColor: '#f6f6f6', 
    color: '#5f5f5f',
    width: '120px',

    ':hover': {
        backgroundColor: '#f6f6f6'
    }
})

const ApplyButton = styled(Button)({
    backgroundColor: '#cff1fc', 
    color: '#5f5f5f',
    width: '120px',

    ':hover': {
        backgroundColor: '#cff1fc'
    }
})

const ProfileCard = (props) => {
    let stars = [];
    for(let i=0; i<props.stars; ++i) {
        stars.push(<img src={StarIcon} alt='star_icon' style={{height: '30px'}} />);
    }

    return (
        <Card>
            <img src={props.profileImage} alt='profile_image' style={{width: '35%'}} />
            <P1 style={{fontSize: '20px'}}>
                {props.name}
            </P1>
            <P1 style={{fontSize: '18px', color: '#00a0e1'}}>
                {props.title}
            </P1>
            <Box sx={{display: 'flex'}}>
                {
                    stars.map((star)=>{
                        return star;
                    })
                }
            </Box>

            <ExperienceBox sx={{display: 'flex', width: '100%'}}>
                <img src={LocationIcon} alt='location' style={{width: '10%'}} />
                <P1 style={{fontSize: '25px'}}>
                    {props.salary}<span style={{fontSize: '15px', color: '#5f5f5f'}}>/Year</span>
                </P1>

                <GoClock style={{marginLeft: '10px'}} />
                <P1 style={{fontSize: '20px', marginLeft: '5px', color: '#5f5f5f'}}>
                    {props.experience} Years Exp.
                </P1>
            </ExperienceBox>

            <Box sx={{display: 'flex', marginTop: '50px', justifyContent: 'space-evenly'}}>
                <DetailButton variant='contained'>
                    <P4 style={{color: '#5f5f5f'}}>
                        View Detail
                    </P4>
                </DetailButton>
                <ApplyButton variant='contained'>
                    <P4 style={{color: '#5f5f5f'}}>
                        Apply
                    </P4>
                </ApplyButton>
            </Box>
        </Card>
    )
}

export default ProfileCard;