import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import Title from './Title';
import DiscoverSolutions from './DiscoverSolutions';
import ExclusiveFeatures from './ExclusiveFeatures';
import Help from './Help';
import Destination from './Destination';
import Process from './Process';
import Review from './Review';
import Queries from './Queries';

const Counselling = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, []);

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Title isMobile={isMobile} />
            <DiscoverSolutions isMobile={isMobile} />
            <ExclusiveFeatures isMobile={isMobile} />
            <Help isMobile={isMobile} />
            <Destination isMobile={isMobile} />
            <Process isMobile={isMobile} />
            <Review isMobile={isMobile} />
            <Queries isMobile={isMobile} />
        </div>
    )
}

export default Counselling;