import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import Title from './Title';
import EdubriqDetails from './EdubriqDetails';
import Queries from './Queries';
import Footprints from './Footprints';
import Services from './Services';

const AboutUs = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, []);

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Title isMobile={isMobile} />
            <EdubriqDetails isMobile={isMobile} />
            <Queries isMobile={isMobile} />
            <Footprints isMobile={isMobile} />
            <Services isMobile={isMobile} />
        </div>
    )
}

export default AboutUs;