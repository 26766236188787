import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import Title from './Title';
import Map from './Map';
import ContactDetails from './ContactDetails';
import Questions from './Questions';

const ContactUs = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, []);

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Title isMobile={isMobile} />
            <Map isMobile={isMobile} />
            <ContactDetails isMobile={isMobile} />
            <Questions isMobile={isMobile} />
        </div>
    )
}

export default ContactUs;