import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';

const TitleContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eef0f1',
    height: '45vh',
    width: '100%',
    maxWidth: '1920px'
})

const Title = () => {
    return (
        <TitleContainer>
            <P2 sx={{color: '#5f5f5f'}}>
                EduBriq Global
            </P2>
            
            <H1 sx={{color: '#5f5f5f', fontWeight: 'bolder', textAlign: 'center'}}>
                Contact <span style={{color: '#00a0e1'}}>Us</span>
            </H1>
        </TitleContainer>
    )
}

export default Title;