import { Box, Grid, Slide, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SliderOne from '../../../images/slider-one.png';
import SliderTwo from '../../../images/slider-one.png';
import { styled } from '@mui/system';

const BannerImage = styled('img')(
    ({ theme }) => `
  margin-top: ${theme.spacing(-15)};
  height: auto;
  width: 100%;
  `
)

const Banner = () => {

    const sliderList = [SliderOne, SliderTwo]

    const [bannerIndex, setBannerIndex] = useState(0)
    const [slideIn, setSlideIn] = useState(false)

    let slide = 0;
    let items = sliderList.length;

    function nextSlide() {
        if (slide < items) {
            setBannerIndex(slide)
            slide++;
        } else if (slide === items) {
            slide = 0;
            setBannerIndex(slide);
        }
    }

    useEffect(() => {
        let interval;
        interval = setInterval(() => {
            nextSlide()
        }, 4000);
        return () => clearInterval(interval);
    }, [bannerIndex])


    return (

        <>
            <BannerImage src={sliderList[bannerIndex]} />
        </>
    )
}

export default Banner