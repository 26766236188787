import { Box, Typography, TextField, Button } from '@mui/material';
import { styled } from '@mui/system';
import { FaPhone } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';

const ContactDetailsContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const ContactAddressContainer = styled(Box)(({theme})=>({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #00a0e1',

    [theme.breakpoints.down('sm')]: {
        width: '100%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '40%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '40%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '40%'
    }
}))

const PhoneIcon = styled(FaPhone)({
    height: '25px',
    width: 'auto',
    marginRight: '10px',
    color: '#7182f7'
})

const EnvelopeIcon = styled(FaEnvelope)({
    height: '25px',
    width: 'auto',
    color: '#7182f7',
    marginLeft: '30px',
    marginRight: '10px'
})

const LinkedinContainer = styled(Box)({
    backgroundColor: '#00a0e1',
    width: '250px',
    textAlign: 'center',
    marginTop: '20px',
    borderRadius: '5px',
    padding: '5px',

    ':hover': {
        cursor: 'pointer'
    }
})

const MessageContainer = styled(Box)(({theme})=>({
    display: 'flex',
    flexDirection: 'column',
    width: '50%',

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: '30px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%',
        marginTop: '30px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '50%',
        marginLeft: '50px'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '50%',
        marginLeft: '50px'
    },
    [theme.breakpoints.up('xl')]: {
        width: '50%',
        marginLeft: '50px'
    }
}))

const ContactDetails = ({ isMobile }) => {
    return (
        <ContactDetailsContainer>
            <ContactAddressContainer>
                <H5 style={{fontWeight: 'bold'}}>
                    EduBriq Global
                </H5>
                <P2 style={{color: '#757575', marginTop: '20px'}}>
                    Suite 103, 661 George Street
                </P2>
                <P2 style={{color: '#757575', marginTop: '10px'}}>
                    Haymarket NSW 2000, Australia
                </P2>

                <Box sx={{display: 'flex', marginTop: '20px'}}>
                    <PhoneIcon />
                    <P2>
                        +61 400 810 105
                    </P2>
                    <EnvelopeIcon />
                    <P2>
                        info@edubriq.com
                    </P2>
                </Box>

                <LinkedinContainer>
                    <P2 sx={{color: 'white'}}>
                        Visit Our LinkedIN Page
                    </P2>
                </LinkedinContainer>
            </ContactAddressContainer>

            <MessageContainer>
                <Box sx={{display: 'flex', borderBottom: '3px solid #00a0e1'}}>
                    <Typography variant='h5' sx={{fontWeight: 'bold', marginRight: '5px'}}>
                        Get In
                    </Typography>
                    <Typography variant='h5' sx={{fontWeight: 'bold', color: '#00a0e1'}}>
                        Touch
                    </Typography>
                </Box>
                <Box sx={{marginTop: '20px', width: '100%'}}>
                    <TextField label='Name' sx={{width: '45%'}} />
                    <TextField label='Subject' sx={{width: '45%', float: 'right'}} />
                </Box>
                <Box sx={{marginTop: '20px', width: '100%'}}>
                    <TextField label='E-mail Address' sx={{width: '45%'}} />
                    <TextField label='Phone' sx={{width: '45%', float: 'right'}} />
                </Box>
                <Box sx={{marginTop: '20px', width: '100%'}}>
                    <TextField label='Message' multiline rows={6} sx={{width: '100%'}} />
                    <Button variant='contained' sx={{marginTop: '40px'}}>
                        <P3>
                            Send Message
                        </P3>
                    </Button>
                </Box>
            </MessageContainer>
        </ContactDetailsContainer>
    )
}

export default ContactDetails;