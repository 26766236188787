import { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import SpecialtyIcon1 from '../../../assets/images/Specialty-icon1.png';
import SpecialtyIcon2 from '../../../assets/images/Specialty-icon2.png';
import Colors from '../../../utility/styles';
import SpecialtyCard from './SpecialtyCard';

const Container = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const TitleContainer = styled(Box)(({theme})=>({
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '30%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '30%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '30%'
    }
}))

const SpecialtyCardContainer = styled(Box)(({theme})=>({
    display: 'flex',
    
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: '30px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%',
        marginTop: '30px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '50%',
        justifyContent: 'space-between',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '50%',
        justifyContent: 'space-between',
    },
    [theme.breakpoints.up('xl')]: {
        width: '50%',
        justifyContent: 'space-between',
    }
}))

const ButtonContainer = styled(Box)({
    display: 'flex',
    width: '100%',
    marginTop: '50px'
})

const ControlBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-around',
    width: '200px'
})

const LeftControl = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    width: '50px',
    borderRadius: '50%',

    ':hover': {
        backgroundColor: `${Colors.lightBlue}`
    },
    ':active': {
        backgroundColor: `${Colors.lightBlue}`
    }
})

const RightControl = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    width: '50px',
    borderRadius: '50%',

    ':hover': {
        backgroundColor: `${Colors.lightBlue}`
    },
    ':active': {
        backgroundColor: `${Colors.lightBlue}`
    }
})

const LeftIcon = styled(SlArrowLeft)({
    fontSize: '20px',
})

const RightIcon = styled(SlArrowRight)({
    fontSize: '20px',
})

const specialtyList = [
    {
        image: SpecialtyIcon1,
        title: 'Student Counselling',
        text: 'Get exper guidance to navigate academic and career paths seamlessly. Our personalized support helps students achieve their goals with confidence.'
    },
    {
        image: SpecialtyIcon2,
        title: 'Training & Education',
        text: 'Unlock your potential with our specialized training and tailored courses. Our programs are crafted to meet the demands of the modern job market, ensuring you stay ahead in your field.'
    },
    {
        image: SpecialtyIcon1,
        title: 'Student Exam',
        text: 'Get exper guidance to navigate academic and career paths seamlessly. Our personalized support helps students achieve their goals with confidence.'
    },
    {
        image: SpecialtyIcon2,
        title: 'Edubriq academy',
        text: 'Unlock your potential with our specialized training and tailored courses. Our programs are crafted to meet the demands of the modern job market, ensuring you stay ahead in your field.'
    },
    {
        image: SpecialtyIcon1,
        title: 'Student LMS',
        text: 'Get exper guidance to navigate academic and career paths seamlessly. Our personalized support helps students achieve their goals with confidence.'
    },
    {
        image: SpecialtyIcon2,
        title: 'Edubriq tutor',
        text: 'Unlock your potential with our specialized training and tailored courses. Our programs are crafted to meet the demands of the modern job market, ensuring you stay ahead in your field.'
    }
]

const Specialties = ({ isMobile }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    let offset = isMobile ? 1 : 2;
    let specialtyListLastIndex = specialtyList.length - 1;

    useEffect(()=>{
        if(isMobile===false && currentIndex%2===1) {
            setCurrentIndex(currentIndex-1);
        }
    }, [isMobile]);

    const handleLeftArrowClick = () => {
        if(currentIndex-offset>=0) {
            setCurrentIndex(currentIndex-offset);
        }
    }

    const handleRightArrowClick = () => {
        if(currentIndex+offset<=specialtyListLastIndex) {
            setCurrentIndex(currentIndex+offset);
        }
    }

    return (
        <Container>
            <TitleContainer>
                <P1>
                    What are the
                </P1>
                <H2>
                    Specialties We Cover!
                </H2>
                <P2 style={{marginTop: '30px'}}>
                    At Edubriq, we specialize in education, career
                    guidance and professional development. From 
                    counseling to tutoring and advanced learning 
                    tools, we empower individuals and organizations
                    for success.
                </P2>
                <ButtonContainer>
                    <Button variant='contained'>
                        View All Services
                    </Button>
                    <ControlBox>
                        <LeftControl onClick={handleLeftArrowClick}>
                            <LeftIcon />
                        </LeftControl>
                        <RightControl onClick={handleRightArrowClick}>
                            <RightIcon />
                        </RightControl>
                    </ControlBox>
                </ButtonContainer>
            </TitleContainer>

            <SpecialtyCardContainer>
                <SpecialtyCard
                    image={specialtyList[currentIndex].image}
                    title={specialtyList[currentIndex].title}
                    text={specialtyList[currentIndex].text}
                />
                {(isMobile===false && currentIndex+1<=specialtyListLastIndex) ?
                    <SpecialtyCard
                        image={specialtyList[currentIndex+1].image}
                        title={specialtyList[currentIndex+1].title}
                        text={specialtyList[currentIndex+1].text}
                    />
                    :
                    <></>
                }
            </SpecialtyCardContainer>
        </Container>
    )
}

export default Specialties;