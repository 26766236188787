import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '100px',
    width: '100%',
    maxWidth: '1920px',
    padding: '0 10px'
})

const ContactUsContainer = styled(P2)({
    color: '#5f5f5f', 
    marginTop: '10px',
    textAlign: 'center'
})

const QuestionContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    align: 'center',
    width: '100%',
    maxWidth: '1000px',
    marginTop: '30px'
})

const Queries = () => {
    const [questionPanelStatus, setQuestionPanelStatus] = useState({'q1': false, 'q2': false, 'q3': false, 'q4': false, 'q5': false});

    return (
        <Container>
            <H2 style={{fontWeight: 'bold', textAlign: 'center'}}>
                Frequently Asked Queries
            </H2>
            <ContactUsContainer> 
                We tried to give few common question's answer <br/>
                Still have questions? <Link to='/contact' style={{textDecoration: 'none'}}><span style={{color: '#00a0e1'}}>Contact Us</span></Link>
            </ContactUsContainer>
            <QuestionContainer>
                <Accordion sx={{width: '100%'}}>
                    <AccordionSummary
                        expandIcon={
                            questionPanelStatus['q1'] ? <FaMinus style={{color: '#00a0e1'}} /> : <FaPlus style={{color: '#00a0e1'}} />
                        }
                        onClick={()=>{
                            setQuestionPanelStatus({...questionPanelStatus, 'q1': !questionPanelStatus['q1']});
                        }}
                    >
                        <P1 style={{fontWeight: 'bold'}}>
                            What is the mission of EduBriq Global?
                        </P1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <P3 style={{color: '#5f5f5f'}}>
                            At EduBriq, our mission is to empower personal and professional growth through
                            tailored education services. We leverage advanced learning platforms and innovative
                            solutions to make education accessible and effective, fostering excellence every
                            step of the way.
                        </P3>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{width: '100%', marginTop: '20px'}}>
                    <AccordionSummary
                        expandIcon={
                            questionPanelStatus['q2'] ? <FaMinus style={{color: '#00a0e1'}} /> : <FaPlus style={{color: '#00a0e1'}} />
                        }
                        onClick={()=>{
                            setQuestionPanelStatus({...questionPanelStatus, 'q2': !questionPanelStatus['q2']});
                        }}
                    >
                        <P1 sx={{fontWeight: 'bold'}}>
                            What are the visions?
                        </P1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <P3 style={{color: '#5f5f5f'}}>
                            At EduBriq Global, our vision is to empower individuals worldwide through accessible and quality education. 
                            We strive to foster a learning environment where innovation, inclusivity, and lifelong learning are celebrated. 
                            Our goal is to equip learners with the skills and knowledge needed to succeed in their academic pursuits and professional careers.
                        </P3>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{width: '100%', marginTop: '20px'}}>
                    <AccordionSummary
                        expandIcon={
                            questionPanelStatus['q3'] ? <FaMinus style={{color: '#00a0e1'}} /> : <FaPlus style={{color: '#00a0e1'}} />
                        }
                        onClick={()=>{
                            setQuestionPanelStatus({...questionPanelStatus, 'q3': !questionPanelStatus['q3']});
                        }}
                    >
                        <P1 style={{fontWeight: 'bold'}}>
                            How EduBriq Global will charge me?
                        </P1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <P3 style={{color: '#5f5f5f'}}>
                            EduBriq Global offers flexible pricing models tailored to suit your learning needs. 
                            You can choose from subscription-based plans for access to courses and services, or pay-as-you-go options for specific resources and features. 
                            Our transparent pricing ensures that you only pay for what you use, with no hidden fees.
                        </P3>
                    </AccordionDetails>
                </Accordion>
                
                <Accordion sx={{width: '100%', marginTop: '20px'}}>
                    <AccordionSummary
                        expandIcon={
                            questionPanelStatus['q4'] ? <FaMinus style={{color: '#00a0e1'}} /> : <FaPlus style={{color: '#00a0e1'}} />
                        }
                        onClick={()=>{
                            setQuestionPanelStatus({...questionPanelStatus, 'q4': !questionPanelStatus['q4']});
                        }}
                    >
                        <P1 style={{fontWeight: 'bold'}}>
                            I live in Bangladesh. How can I take service from EduBriq Global?
                        </P1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <P3 style={{color: '#5f5f5f'}}>
                            Living in Bangladesh is no barrier to accessing EduBriq Global's services. 
                            Our platform is accessible worldwide, allowing you to enroll in courses, participate in tutoring sessions, and access resources from the comfort of your home. 
                            Simply visit our website, create an account, and start exploring the educational opportunities available to you.
                        </P3>
                    </AccordionDetails>
                </Accordion>

                <Accordion sx={{width: '100%', marginTop: '20px'}}>
                    <AccordionSummary
                        expandIcon={
                            questionPanelStatus['q5'] ? <FaMinus style={{color: '#00a0e1'}} /> : <FaPlus style={{color: '#00a0e1'}} />
                        }
                        onClick={()=>{
                            setQuestionPanelStatus({...questionPanelStatus, 'q5': !questionPanelStatus['q5']});
                        }}
                    >
                        <P1 style={{fontWeight: 'bold'}}>
                            How EduBriq Global will help me to get the Admission letter and Visa?
                        </P1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <P3 style={{color: '#5f5f5f'}}>
                            EduBriq Global provides comprehensive support throughout your academic journey, including assistance with admission letters and visa processes. 
                            Our counseling services (EduBriq Counseling) offer guidance on university applications, document preparation, and visa requirements. 
                            We partner with educational institutions and immigration experts to ensure a smooth transition and help you achieve your study abroad goals.
                        </P3>
                    </AccordionDetails>
                </Accordion>

            </QuestionContainer>
        </Container>
    )
}

export default Queries;