import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import PersonIcon from '../../../assets/images/Contact-us-icon1.png';
import ChatBubbleIcon from '../../../assets/images/Contact-us-icon2.png';
import BuildingIcon from '../../../assets/images/Contact-us-icon3.png';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    backgroundColor: '#edf8fd',
    padding: '0 10px 100px'
})

const TextContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
})

const OptionContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: '50px',
})

const IconContainer = styled(Box)(({theme})=>({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #5f5f5f',
    width: '20%',
    padding: '30px',

    [theme.breakpoints.down('sm')]: {
        width: '60%',
        marginTop: '5%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '60%',
        marginTop: '5%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '20%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '20%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '20%'
    }
}))

const IconText = styled(P2)({
    color: '#5f5f5f', 
    fontWeight: 'bold', 
    marginTop: '5%',
    textAlign: 'center'
})

const Questions = () => {
    return (
        <Container>
            <TextContainer>
                <H6 style={{color: '#5f5f5f', fontWeight: 'bold'}}>
                    Have You Any Questions
                </H6>
                <H2 style={{color: '#5f5f5f', fontWeight: 'bold'}}>
                    Will You Want To Know More?
                </H2>
            </TextContainer>

            <OptionContainer sx={{marginTop: '50px'}}>
                <IconContainer>
                    <img src={PersonIcon} alt='person_icon' style={{width: '60%', height: 'auto'}} />
                    <IconText>
                        Talk to An Agent
                    </IconText>
                    <P3 style={{color: '#5f5f5f'}}>
                        Get a Call
                    </P3>
                </IconContainer>

                <IconContainer>
                    <img src={ChatBubbleIcon} alt='person_icon' style={{width: '60%', height: 'auto'}} />
                    <IconText>
                        Chat With An Agent
                    </IconText>
                    <P3 style={{color: '#5f5f5f'}}>
                        Chat Now
                    </P3>
                </IconContainer>

                <IconContainer>
                    <img src={BuildingIcon} alt='person_icon' style={{width: '60%', height: 'auto'}} />
                    <IconText>
                        Visit Our Office
                    </IconText>
                    <P3 style={{color: '#5f5f5f'}}>
                        Set an Appointment
                    </P3>
                </IconContainer>
            </OptionContainer>
        </Container>
    )
}

export default Questions;