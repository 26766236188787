import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import ExamBanner2 from '../../../assets/images/Exam-banner2.png';

const Container = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const ExclusiveFeatures = ({ isMobile }) => {
    return (
        <Container>
            {isMobile ?
            <>
            <img src={ExamBanner2} alt='feature_image' style={{width: '100%'}} />
            <Box sx={{marginLeft: '20px', width: '100%'}}>
                <H2 style={{marginTop: '100px', fontWeight: 'bold'}}>
                    Exclusive Features
                    & Services
                </H2>
                <Box sx={{width: '100%'}}>
                    <P2 style={{color: '#5f5f5f', textAlign: 'justify'}}>
                        Experience unparalleled efficiency with our
                        exclusive AI-driven exam proctoring, ensuring
                        academic integrity and reducing administritive
                        workload. <br />
                        <ul style={{marginTop: '20px'}}>
                            <li className='point'>Dedicated 24/7 support service guaranteed</li>
                            <li className='point'>Resonable Subscription Fees around the year</li>
                            <li className='point'>Use Demo before actual Purchase</li>
                        </ul>
                    </P2>
                    <Button variant='contained' color='error' sx={{marginTop: '50px'}}>
                        <P2>
                            Get Started
                        </P2>
                    </Button>
                </Box>
            </Box>
            </>
            :
            <>
            <img src={ExamBanner2} alt='feature_image' style={{width: '40%'}} />
            <Box sx={{marginLeft: '50px', width: '40%'}}>
                <H2 style={{marginTop: '100px', fontWeight: 'bold'}}>
                    Exclusive Features
                    & Services
                </H2>
                <Box sx={{width: '100%'}}>
                    <P2 style={{color: '#5f5f5f', textAlign: 'justify'}}>
                        Experience unparalleled efficiency with our
                        exclusive AI-driven exam proctoring, ensuring
                        academic integrity and reducing administritive
                        workload. <br />
                        <ul style={{marginTop: '20px'}}>
                            <li className='point'>Dedicated 24/7 support service guaranteed</li>
                            <li className='point'>Resonable Subscription Fees around the year</li>
                            <li className='point'>Use Demo before actual Purchase</li>
                        </ul>
                    </P2>
                    <Button variant='contained' color='error' sx={{marginTop: '50px'}}>
                        <P2>
                            Get Started
                        </P2>
                    </Button>
                </Box>
            </Box>
            </>
            }
        </Container>
    )
}

export default ExclusiveFeatures;