import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import ServiceIcon from '../../../assets/images/Service-icon.png';
import ServiceCard from './ServiceCard.js';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1920px',
    padding: '0 10px',

    // [theme.breakpoints.down('sm')]: {
    //     height: '100vh'
    // },
    // [theme.breakpoints.between('sm', 'md')]: {
    //     marginTop: '50px',
    //     height: 'fit-content'
    // },
    // [theme.breakpoints.between('md', 'lg')]: {
    //     height: 'fit-content',
    //     alignItems: 'center',
    // },
    // [theme.breakpoints.between('lg', 'xl')]: {
    //     height: 'fit-content',
    //     alignItems: 'center',
    // },
    // [theme.breakpoints.up('xl')]: {
    //     height: 'fit-content',
    //     alignItems: 'center',
    // }
})

const ServiceContainer = styled(Box)(({theme})=>({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    width: '100%',
    
    [theme.breakpoints.down('sm')]: {
        height: '100vh'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        marginTop: '50px',
        height: 'fit-content'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        height: 'fit-content',
        alignItems: 'center',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        height: 'fit-content',
        alignItems: 'center',
    },
    [theme.breakpoints.up('xl')]: {
        height: 'fit-content',
        alignItems: 'center',
    }
}))

const BlueDesign = styled(Box)({
    position: 'absolute',
    width: '100%',
    height: '20px',
    backgroundColor: '#bde4ff',
    zIndex: '1'
})

const serviceList = [
    {
        backgroundColor: 'white',
        icon: ServiceIcon,
        title1: 'Edubriq',
        title2: 'LMS',
        text: 'Transform your learning experience with our state-of-the-art Learning Management System, designed to deliver engaging and interactive online courses.'
    },
    {
        backgroundColor: '#fcfce6',
        icon: ServiceIcon,
        title1: 'Edubriq',
        title2: 'Exam',
        text: 'Achieve exam success with our comprehensive test preparation tools and resources, tailored to help you excel in your academic and professional assessments.'
    },
    {
        backgroundColor: 'white',
        icon: ServiceIcon,
        title1: 'Edubriq',
        title2: 'Tutor',
        text: 'Connect with expert tutors for personalized, one-on-one instruction and support, helping you master subjects and achieve your learning goals.'
    },
    {
        backgroundColor: 'white',
        icon: ServiceIcon,
        title1: 'Edubriq',
        title2: 'Academy',
        text: 'Explore a diverse range of courses and programs at EduBriq Academy, where quality education meets flexible learning opportunities.'
    },
    {
        backgroundColor: 'white',
        icon: ServiceIcon,
        title1: 'Edubriq',
        title2: 'Counseling',
        text: 'Navigate your educational and career journey with confidence through our dedicated counseling services, providing guidance and support every step of the way.'
    },
    {
        backgroundColor: 'white',
        icon: ServiceIcon,
        title1: 'Edubriq',
        title2: 'Job Support',
        text: 'Transition seamlessly from education to employment with our job support services, including resume building, interview preparation, and job placement assistance.'
    },
]

const Services = ({ isMobile }) => {
    return (
        <Container>
            <Box style={{width: isMobile ? '100%' : '88%'}}>
                <H2>
                    Explore The Unlimited Possibilities
                </H2>
            </Box>
            <ServiceContainer sx={{marginTop: '50px'}}>
                {isMobile ? <></> : <BlueDesign />}
                <ServiceCard 
                    backgroundColor={serviceList[0].backgroundColor} 
                    icon={serviceList[0].icon} 
                    title1={serviceList[0].title1}
                    title2={serviceList[0].title2}
                    text={serviceList[0].text}
                />
                
                <ServiceCard 
                    backgroundColor={serviceList[1].backgroundColor} 
                    icon={serviceList[1].icon} 
                    title1={serviceList[1].title1}
                    title2={serviceList[1].title2}
                    text={serviceList[1].text}
                    fontWeight='bold'
                />

                <ServiceCard 
                    backgroundColor={serviceList[2].backgroundColor} 
                    icon={serviceList[2].icon} 
                    title1={serviceList[2].title1}
                    title2={serviceList[2].title2}
                    text={serviceList[2].text}
                />
            </ServiceContainer>

            <ServiceContainer sx={{marginTop: isMobile ? '0' : '50px'}}>
                {isMobile ? <></> : <BlueDesign />}
                <ServiceCard 
                    backgroundColor={serviceList[3].backgroundColor} 
                    icon={serviceList[3].icon} 
                    title1={serviceList[3].title1}
                    title2={serviceList[3].title2}
                    text={serviceList[3].text}
                />
                
                <ServiceCard 
                    backgroundColor={serviceList[4].backgroundColor} 
                    icon={serviceList[4].icon} 
                    title1={serviceList[4].title1}
                    title2={serviceList[4].title2}
                    text={serviceList[4].text}
                />

                <ServiceCard 
                    backgroundColor={serviceList[5].backgroundColor} 
                    icon={serviceList[5].icon} 
                    title1={serviceList[5].title1}
                    title2={serviceList[5].title2}
                    text={serviceList[5].text}
                />
            </ServiceContainer>
        </Container>
    )
}

export default Services;