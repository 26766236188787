import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import LmsBanner from '../../../assets/images/LMS-Banner.png';
import Colors from '../../../utility/styles.js';

const Container = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '15%',
    padding: '0 10px',
})

const DetailsContainer = styled(Box)(({theme})=>({
    height: '100%',
    color: '#5f5f5f',

    [theme.breakpoints.down('sm')]: {
        width: '100%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '50%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '50%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '50%'
    }
}))

const ImageContainer = styled(Box)(({theme})=>({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
        width: '80%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '80%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '50%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '50%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '50%'
    }
}))

const BlueDesign = styled(Box)({
    position: 'absolute', 
    height: '140%', 
    width: '8%', 
    backgroundColor: '#00a0e1', 
    zIndex: '1'
})

const Details = ({ isMobile }) => {
    return (
        <Container>
            { isMobile ?
            <>
                <ImageContainer>
                    <img src={LmsBanner} alt='lms_banner' style={{width: '100%', zIndex: '2'}}/>
                    <BlueDesign />
                </ImageContainer>
                <DetailsContainer sx={{marginTop: '15%'}}>
                    <H2 style={{fontWeight: 'bold'}}>
                        What is <span style={{color: '#00a0e1'}}>EduBriq LMS?</span>
                    </H2>
                    <P2 style={{textAlign: 'justify', marginTop: '30px'}}>
                        EduBriq LMS is a robust learning management system
                        tailored to enhance online education and training by 
                        providing comprehensive tools for course management,
                        delivery and tracking. It features user management
                        capabilities that allow administrators to create accounts,
                        assign roles, monitor progress, while its course 
                        management tools enable the organization and 
                        distribution of multimedia educational content. EduBriq
                        LMS also includes assesment tools for creating quizzes
                        and assignments as well as communication features 
                        such as discussion forums and messaging systems to 
                        facilitate interaction between learners and instructors.
                        Additionally, the platform supports mobile learning,
                        ensuring accessibility from any device, and integrates
                        with third-party applications to enhance its functionality.
                        Security and data protection are prioritized, ensuring the 
                        safe handling of user information. Overall, EduBriq LMS
                        serves as a versatile and secure platform designed to
                        meet the needs of modern educational environments.
                    </P2>

                    <Button variant='contained' color='warning' sx={{marginTop: '50px'}}> 
                        <P2 style={{fontWeight: 'bold'}}>
                            Download Brochure
                        </P2>
                    </Button>
                </DetailsContainer>
            </>
            :
            <>
                <DetailsContainer>
                    <H2 style={{fontWeight: 'bold'}}>
                        What is <span style={{color: '#00a0e1'}}>EduBriq LMS?</span>
                    </H2>
                    <P2 style={{textAlign: 'justify', marginTop: '30px'}}>
                        EduBriq LMS is a robust learning management system
                        tailored to enhance online education and training by 
                        providing comprehensive tools for course management,
                        delivery and tracking. It features user management
                        capabilities that allow administrators to create accounts,
                        assign roles, monitor progress, while its course 
                        management tools enable the organization and 
                        distribution of multimedia educational content. EduBriq
                        LMS also includes assesment tools for creating quizzes
                        and assignments as well as communication features 
                        such as discussion forums and messaging systems to 
                        facilitate interaction between learners and instructors.
                        Additionally, the platform supports mobile learning,
                        ensuring accessibility from any device, and integrates
                        with third-party applications to enhance its functionality.
                        Security and data protection are prioritized, ensuring the 
                        safe handling of user information. Overall, EduBriq LMS
                        serves as a versatile and secure platform designed to
                        meet the needs of modern educational environments.
                    </P2>

                    <Button variant='contained' color='warning' sx={{marginTop: '50px'}}> 
                        <P2 style={{fontWeight: 'bold'}}>
                            Download Brochure
                        </P2>
                    </Button>
                </DetailsContainer>

                <ImageContainer>
                    <img src={LmsBanner} alt='lms_banner' style={{width: '80%', zIndex: '2'}}/>
                    <BlueDesign />
                </ImageContainer>
            </>
        }
        </Container>
    )
}

export default Details;