import logo from './logo.svg';
import './App.css';
import Brochure from './layouts/Brochure';
import NavBar from './layouts/NavBar';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import WebHome from './views/WebHome';
import Home from './views/Home';
import Footer from './layouts/Footer';
import ContactUs from './views/ContactUs';
import AboutUs from './views/AboutUs';
import Lms from './views/Lms';
import ExamManagement from './views/ExamManagement';
import TutorManagement from './views/TutorManagement';
import Counselling from './views/Counselling';
import Academy from './views/Academy';
import JobSupport from './views/JobSupport';

function App() {
  
  return (
    <BrowserRouter>
      <Brochure />
      <NavBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='contact' element={<ContactUs />} />
        <Route path='about' element={<AboutUs />} />
        <Route path='lms' element={<Lms />} />
        <Route path='exam' element={<ExamManagement />} />
        <Route path='tutor' element={<TutorManagement />} />
        <Route path='counselling' element={<Counselling />} />
        <Route path='academy' element={<Academy />} />
        <Route path='jobsupport' element={<JobSupport />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
