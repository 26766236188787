import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import DetailsImage from '../../../assets/images/About-Us-Banner-Image.png';

const Container = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const DetailsContainer = styled(Box)({
    width: '50%',
    marginTop: '30px',
})

const EdubriqDetails = ({ isMobile }) => {
    return (
        <Container>
            { isMobile ?
            <>
                <img src={DetailsImage} alt='details_image'  style={{height: 'auto', width: '80%'}} />

                <DetailsContainer sx={{width: '100%'}}>
                    <H2 style={{fontWeight: 'bold', color: '#5f5f5f'}}>
                        What is <span style={{color: '#00a0e1'}}>EduBriq Global?</span>
                    </H2>
                    <P2 style={{marginTop: '50px', color: '#5f5f5f', textAlign: 'justify'}}>
                        Welcome to EduBriq, where we're more than just an 
                        education service provider - we're your dedicated
                        partner in your journey towards personal and 
                        professional growth. With a wide array of comprehensive
                        services, including student counseling, tutoring and
                        specialized training, we tailor our offerings to suit your
                        unique needs and aspirations. Whether you're a student
                        seeking guidance on your academic path or a
                        professional looking to enhance your skill set, we're here
                        to support you every step of the way. Our solutions go
                        beyond traditional learning methods; we offer advanced
                        learning platform that leverage technology to make
                        education accessible and engaging. Additionally, our 
                        online exam proctoring ensures academic integrity, while 
                        our HR management tools streamline organizational 
                        processes for maximum efficiency. At Edubriq, we're
                        committed to empowering individuals and organizations
                        with the skills and support needed to succeed in today's
                        dynamic world. Join us as we embark together on the
                        journey to excellence.
                    </P2>
                </DetailsContainer>
            </>
            :
            <>
                <DetailsContainer sx={{marginRight: '20px'}}>
                    <H2 style={{fontWeight: 'bold', color: '#5f5f5f'}}>
                        What is <span style={{color: '#00a0e1'}}>EduBriq Global?</span>
                    </H2>
                    <P2 style={{marginTop: '50px', color: '#5f5f5f', textAlign: 'justify'}}>
                        Welcome to EduBriq, where we're more than just an 
                        education service provider - we're your dedicated
                        partner in your journey towards personal and 
                        professional growth. With a wide array of comprehensive
                        services, including student counseling, tutoring and
                        specialized training, we tailor our offerings to suit your
                        unique needs and aspirations. Whether you're a student
                        seeking guidance on your academic path or a
                        professional looking to enhance your skill set, we're here
                        to support you every step of the way. Our solutions go
                        beyond traditional learning methods; we offer advanced
                        learning platform that leverage technology to make
                        education accessible and engaging. Additionally, our 
                        online exam proctoring ensures academic integrity, while 
                        our HR management tools streamline organizational 
                        processes for maximum efficiency. At Edubriq, we're
                        committed to empowering individuals and organizations
                        with the skills and support needed to succeed in today's
                        dynamic world. Join us as we embark together on the
                        journey to excellence.
                    </P2>
                </DetailsContainer>

                <img src={DetailsImage} alt='details_image'  style={{height: 'auto', width: '40%'}} />
            </>
            }
        </Container>
    )
}

export default EdubriqDetails;