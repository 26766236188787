import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import { styled } from '@mui/system';
import AboutUsBackgroundImage from '../../../images/About-Image.png';

const AboutSection = styled(Box)(({ theme }) => ({
    //backgroundColor: '#f0f4ff', // Light blue background for the section
    paddingTop: theme.spacing(22),
    paddingBottom: theme.spacing(22)
    // borderRadius: '0 0 50% 50% / 0 0 10% 10%', // To create the curved effect at the bottom
}));

const AboutUs = () => {
    return (
        <AboutSection
            sx={{
                
                display: 'flex',
                backgroundImage: `url(${AboutUsBackgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: "100%",
                mt: 10,
                mb: 5
            }}
        >
            <Grid container spacing={4} alignItems="center" sx={{
                paddingLeft: 4
            }}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" component="h2" gutterBottom>
                        About EduBriq!
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Welcome to Edubriq, your partner in education, career guidance, and professional development.
                        We offer comprehensive services including student counseling, tutoring, and specialized training.
                        Our solutions encompass advanced learning platforms, online exam proctoring, and HR management tools.
                        At Edubriq, we are dedicated to empowering individuals and organizations with the skills and support
                        needed for success. Join us in your journey to excellence.
                    </Typography>
                    <Button variant="contained" color="primary">
                        Find More
                    </Button>
                </Grid>
            </Grid>
        </AboutSection>
    )
}

export default AboutUs