import React, { useState } from 'react'
import ServiceRow from './ServiceRow'
import { Box, Grid, Typography, styled } from '@mui/material'
import { NavigationLink } from '../../../components/Hyperlink'
import Icon from '../../../images/Icon.png';
import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs';
import COLORS from '../../../utility/styles';
import Button from '../../../components/Button';
import SubHeader from '../../../components/SubHeader';
import CounsellinIcon from '../../../images/SP_counceling_icon.png';
import TrainingIcon from '../../../images/SP_training_icon.png';

const PaginationIcon = styled('div')(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLORS.lightBlue};
  border: 1px solid ${COLORS.lightBlue};
  border-radius: 50%;
  padding: ${theme.spacing(2)};
  margin-left: ${theme.spacing(4)};
  cursor: pointer
  `
)

const HBox = styled('div')(
  ({ theme }) => `
   display: flex;
   justify-content: center;
   align-items: center;
   `
)

const serviceDictionary = {
  1: [
    {
      image: CounsellinIcon,
      serviceHeader: 'Student Councelling',
      serviceBangla: 'Get expert quidance to naviaate bath. seamlessiv Oui personalized suboort helos students achieve'
    },
    {
      image: TrainingIcon,
      serviceHeader: 'Training & Eductaion',
      serviceBangla: 'Get expert quidance to naviaate bath. seamlessiv Oui personalized suboort helos students achieve'
    }

  ],
  2: [
    {
      image: CounsellinIcon,
      serviceHeader: 'Student Councelling',
      serviceBangla: 'Get expert quidance to naviaate bath. seamlessiv Oui personalized suboort helos students achieve'
    },
    {
      image: TrainingIcon,
      serviceHeader: 'Training & Eductaion',
      serviceBangla: 'Get expert quidance to naviaate bath. seamlessiv Oui personalized suboort helos students achieve'
    }
  ],
  3: [
    {
      image: CounsellinIcon,
      serviceHeader: 'Student Councelling',
      serviceBangla: 'Get expert quidance to naviaate bath. seamlessiv Oui personalized suboort helos students achieve'
    },
    {
      image: TrainingIcon,
      serviceHeader: 'Training & Eductaion',
      serviceBangla: 'Get expert quidance to naviaate bath. seamlessiv Oui personalized suboort helos students achieve'
    }
  ],
  4: [
    {
      image: CounsellinIcon,
      serviceHeader: 'Student Councelling',
      serviceBangla: 'Get expert quidance to naviaate bath. seamlessiv Oui personalized suboort helos students achieve'
    },
    {
      image: TrainingIcon,
      serviceHeader: 'Training & Eductaion',
      serviceBangla: 'Get expert quidance to naviaate bath. seamlessiv Oui personalized suboort helos students achieve'
    }
  ],
  5: [
    {
      image: CounsellinIcon,
      serviceHeader: 'Student Councelling',
      serviceBangla: 'Get expert quidance to naviaate bath. seamlessiv Oui personalized suboort helos students achieve'
    },
    {
      image: TrainingIcon,
      serviceHeader: 'Training & Eductaion',
      serviceBangla: 'Get expert quidance to naviaate bath. seamlessiv Oui personalized suboort helos students achieve'
    }
  ],
  6: [
    {
      image: CounsellinIcon,
      serviceHeader: 'Student Councelling',
      serviceBangla: 'Get expert quidance to naviaate bath. seamlessiv Oui personalized suboort helos students achieve'
    },
    {
      image: TrainingIcon,
      serviceHeader: 'Training & Eductaion',
      serviceBangla: 'Get expert quidance to naviaate bath. seamlessiv Oui personalized suboort helos students achieve'
    }
  ]
}

const Services = () => {
  const [serviceIndex, setServiceIndex] = useState(1);

  var lastServiceIndex = Object.keys(serviceDictionary).pop();

  const handleClickForward = () => {
    if (serviceIndex < lastServiceIndex) {
      setServiceIndex(serviceIndex + 1);
    }
  }

  const handleClickBackward = () => {
    if (serviceIndex > 1) {
      setServiceIndex(serviceIndex - 1);
    }
  }

  return (
    <Grid
      container
      spacing={2}
      display='flex'
      justifyContent='center'
      id='services'
    >
      <Grid item xl={5} lg={4} md={8} sm={12} xs={12} >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 4
          }}
        >
          <SubHeader title='What are the' color='black' />
          <Typography variant='h5'>
            <Box
              sx={{
                color: 'black',
                fontWeight: 'bold'
              }}
            >
              Specialities We Cover!
            </Box>
          </Typography>
          <Typography variant='body2'>
            <Box
              sx={{
                pt: 2,
                pb: 2,
                pr: 5
              }}
            >
              {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's 
                        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled 
                        it to make a type specimen book. */}
            </Box>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              pt: 4,
              pb: 2,
              justifyContent: 'space-between'
            }}
          >
            <div><NavigationLink to='/specialities'><Button name='View All Specialities' color='black' /></NavigationLink></div>
            <HBox >
              <PaginationIcon onClick={handleClickBackward}><BsChevronDoubleLeft /></PaginationIcon>
              <PaginationIcon onClick={handleClickForward}><BsChevronDoubleRight /></PaginationIcon>
            </HBox>
          </Box>
        </Box>
      </Grid>
      <Grid item xl={6} lg={8} md={8} sm={12} xs={12} display='flex' justifyContent='center'>
        <ServiceRow serviceIndex={serviceIndex} serviceDictionary={serviceDictionary} />
      </Grid>
    </Grid>
  )
}

export default Services