import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import ExamBanner1 from '../../../assets/images/Exam-banner1.png';

const Container = styled(Box)(({theme})=>({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '30px',
    padding: '0 10px',
}))

const Demo = ({ isMobile }) => {
    return (
        <Container>
            {isMobile ?
            <>
                <img src={ExamBanner1} alt='image' style={{width: '80%'}}/>
                <Box sx={{marginTop: '30px'}}>
                    <H2 style={{fontWeight: 'bold'}}>
                        Streamline Success!
                    </H2>
                    <H2 style={{fontWeight: 'bold'}}>
                        Effortless Platform for Educators
                    </H2>
                    <P2 style={{color: '#5f5f5f', marginTop: '30px'}}>
                        Empowering Teachers with Innovative Tools for Efficient Exam Planning <br />
                        and Administration
                    </P2>

                    <Button variant='contained' sx={{marginTop: '80px', width: 'fit-content'}}>
                        <P2 variant='h7'>
                            Request for Demo
                        </P2>
                    </Button>
                </Box>
            </>
            :
            <>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    <H2 style={{fontWeight: 'bold'}}>
                        Streamline Success!
                    </H2>
                    <H2 style={{fontWeight: 'bold'}}>
                        Effortless Platform for Educators
                    </H2>
                    <P2 style={{color: '#5f5f5f', marginTop: '30px'}}>
                        Empowering Teachers with Innovative Tools for Efficient Exam Planning <br />
                        and Administration
                    </P2>

                    <Button variant='contained' sx={{marginTop: '80px', width: 'fit-content'}}>
                        <P2 variant='h7'>
                            Request for Demo
                        </P2>
                    </Button>
                </Box>
                <img src={ExamBanner1} alt='image' style={{marginLeft: '30px', width: '30%'}} />
            </>
            }
        </Container>
    )
}

export default Demo;