import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import colors from '../../../utility/styles';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const ItemContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: '30px'
})

const Item = styled(Box)(({theme})=>({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: '30px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%',
        marginTop: '30px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '30%',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '30%',
    },
    [theme.breakpoints.up('xl')]: {
        width: '30%',
    }
}))

const ItemNumber = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#00a0e1',
    width: '80px',
    height: '80px'
})

const HowItWorks = ({ isMobile }) => {
    return (
        <Container>
            <H2>
                How It Works?
            </H2>
            <ItemContainer>
                <Item>
                    <ItemNumber>
                        <H3 style={{fontWeight: 'bolder', color: 'white', fontSize: '50px'}}>
                            1
                        </H3>
                    </ItemNumber>
                    <H4 style={{marginTop: '30px'}}>
                        Create Account
                    </H4>
                    <P2 style={{color: '#5f5f5f', textAlign: 'center'}}>
                        It's very easy to open an <br />
                        account and start your <br />
                        journey.
                    </P2>
                </Item>

                <Item>
                    <ItemNumber>
                        <H3 style={{fontWeight: 'bolder', color: 'white', fontSize: '50px'}}>
                            2
                        </H3>
                    </ItemNumber>
                    <H4 style={{marginTop: '30px'}}>
                        Complete Profile
                    </H4>
                    <P2 style={{color: '#5f5f5f', textAlign: 'center'}}>
                        Complete your profile with <br />
                        all the info to get attention <br />
                        of client.
                    </P2>
                </Item>

                <Item>
                    <ItemNumber>
                        <H3 style={{fontWeight: 'bolder', color: 'white', fontSize: '50px'}}>
                            3
                        </H3>
                    </ItemNumber>
                    <H4 style={{marginTop: '30px'}}>
                        How It Works?
                    </H4>
                    <P2 style={{color: '#5f5f5f', textAlign: 'center'}}>
                        Apply & get your <br />
                        preferable jobs with all the <br />
                        requirements and get it.
                    </P2>
                </Item>
            </ItemContainer>

        </Container>
    )
}

export default HowItWorks;