import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import AboutUsImage from '../../../assets/images/Home-about-us-image.png';
import BackgroundImage from '../../../assets/images/Home-about-us-design.png';


const Container = styled(Box)((props)=>({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1920px',
    backgroundImage: `url(${props.backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'bottom',
    backgroundSize: 'contain',
    marginTop: '100px',
    padding: '0 10px'
}))

const TextContainer = styled(Box)(({theme})=>({
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: '50px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%',
        marginTop: '50px'
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '40%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '40%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '40%'
    }
}))

const AboutUs = ({ isMobile }) => {
    return (
        <Container backgroundImage={BackgroundImage}>
            {isMobile ?
            <>
                <img src={AboutUsImage} alt='image' style={{width: isMobile ? '100%' : '40%'}} />
                <TextContainer>
                    <H2>
                        About EduBriq!
                    </H2>
                    <P2 style={{textAlign: 'justify', marginTop: '50px', color: '#5f5f5f'}}>
                        EduBriq Global is a leading EdTech platform dedicated to revolutionizing education and empowering learners worldwide. We offer a diverse range of online learning solutions designed to meet the educational and professional needs of individuals across various disciplines. <br /> <br />
                        At EduBriq Global, we believe in the transformative power of education. Our platform integrates cutting-edge technology with expert instruction to deliver engaging and effective learning experiences. Whether you're a student looking to excel academically, a professional seeking career advancement, or an individual committed to lifelong learning, EduBriq Global provides the tools and support to help you achieve your goals. <br /> <br />
                        Our commitment extends beyond traditional learning methods. We foster a community of learners and educators who collaborate, innovate, and inspire each other. Through our comprehensive services such as the Learning Management System (EduBriq LMS), personalized tutoring (EduBriq Tutor), and career development resources (EduBriq Job Support), we empower individuals to navigate their educational and professional journeys with confidence. <br /> <br />
                        Join us at EduBriq Global and discover a world of opportunities where education meets innovation, empowerment, and excellence.
                    </P2>    
                </TextContainer>
            </>
            :
            <>
                <TextContainer>
                    <H2>
                        About EduBriq!
                    </H2>
                    <P2 style={{textAlign: 'justify', marginTop: '50px', color: '#5f5f5f'}}>
                        EduBriq Global is a leading EdTech platform dedicated to revolutionizing education and empowering learners worldwide. We offer a diverse range of online learning solutions designed to meet the educational and professional needs of individuals across various disciplines. <br /> <br />
                        At EduBriq Global, we believe in the transformative power of education. Our platform integrates cutting-edge technology with expert instruction to deliver engaging and effective learning experiences. Whether you're a student looking to excel academically, a professional seeking career advancement, or an individual committed to lifelong learning, EduBriq Global provides the tools and support to help you achieve your goals. <br /> <br />
                        Our commitment extends beyond traditional learning methods. We foster a community of learners and educators who collaborate, innovate, and inspire each other. Through our comprehensive services such as the Learning Management System (EduBriq LMS), personalized tutoring (EduBriq Tutor), and career development resources (EduBriq Job Support), we empower individuals to navigate their educational and professional journeys with confidence. <br /> <br />
                        Join us at EduBriq Global and discover a world of opportunities where education meets innovation, empowerment, and excellence.
                    </P2>    
                </TextContainer>
                <img src={AboutUsImage} alt='image' style={{width: '40%'}} />
            </>
            }
        </Container>
    )
}

export default AboutUs;