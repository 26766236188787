import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import colors from '../../../utility/styles';

const Card = styled(Box)((props)=>({
    width: '300px',
    height: '300px',
    backgroundColor: props.backgroundColor ? props.backgroundColor : '#f2f2f2'
}))

const JobCard = (props) => {
    return (
        <Card backgroundColor={props.backgroundColor ? props.backgroundColor : ''}>
            {props.image ?
                <>
                    <img src={props.image} alt='job_image' style={{width: '45%'}} />
                    <P2 style={{marginLeft: '30px', color: '#00a0e1', fontSize: '25px'}}>
                        {props.text1}
                    </P2>
                    <P2 style={{marginLeft: '30px', marginTop: '10px', color: '#00a0e1', fontSize: '25px'}}>
                        {props.text2}
                    </P2>
                    <P3 style={{marginLeft: '30px', marginTop: '20px', color: '#5f5f5f', fontSize: '25px'}}>
                        {props.text3}
                    </P3>
                </>
                : 
                <>
                    <P1 style={{color: '#00a0e1', fontSize: '100px', margin: '20px 0 0 20px'}}>
                        {props.text1}
                    </P1>
                    <P1 style={{color: '#5f5f5f', fontSize: '25px', margin: '0 0 0 35px'}}>
                        {props.text2}
                    </P1>
                    <P1 style={{color: '#5f5f5f', fontSize: '50px', margin: '10px 0 0 35px'}}>
                        {props.text3}
                    </P1>
                </>
            }

        </Card>
    )
}

export default JobCard;