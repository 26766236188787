import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';
import CounsellingImage from '../../../assets/images/Counselling-Image.png';
import colors from '../../../utility/styles';

const Container = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '1920px',
    marginTop: '100px',
    padding: '0 10px'
})

const ExperienceTextBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    top: '35%',
    right: '10%',
    height: 'fit-content',
    width: '200px',
    backgroundColor: 'white',
    border: `5px solid ${colors.facebookColor}`
})

const ExclusiveFeatures = ({ isMobile }) => {
    return (
        <Container>
            <Box sx={{display: 'flex'}}>
                <img src={CounsellingImage} alt='image' style={{width: '60%'}} />
                <ExperienceTextBox>
                    <P2 style={{textAlign: 'center'}}>
                        Leading Visa & <br />
                        Immigration lawyers <br />
                        with
                    </P2>
                    <H2 style={{color: `${colors.redColor}`, fontWeight: 'bold'}}>
                        12
                    </H2>
                    <P2 style={{color: `${colors.redColor}`, textAlign: 'center'}}>
                        Years Of Experience
                    </P2>
                </ExperienceTextBox>
            </Box>
            <Box sx={{width: isMobile ? '100%' : '40%'}}>
                <H2 style={{marginTop: '50px'}}>
                    Exclusive Features <br />
                    & Services
                </H2>
                <P2 style={{textAlign: 'justify', color: '#5f5f5f', marginTop: '20px'}}>
                    More than just a traditional Visa & Immigration
                    Firm: We offer customized, all-encompassing
                    immigration solutions designed to fit your
                    individual journey.
                </P2>
                <ul style={{paddingLeft: '30px', marginTop: '20px'}}>
                    <li className='point'>
                        <P2 style={{color: '#5f5f5f'}}>
                            Solutions tailored to your unique needs.
                        </P2>
                    </li>
                    <li className='point'>
                        <P2 style={{color: '#5f5f5f'}}>
                            From application to settlement.        
                        </P2>
                    </li>
                    <li className='point'>
                        <P2 style={{color: '#5f5f5f'}}>
                            Navigating international immigration with ease.        
                        </P2>
                    </li>
                </ul>
                <Button variant='contained' sx={{marginTop: '50px', fontWeight: 'bold'}}>
                    <P2>Get Started</P2>
                </Button>
            </Box>
        </Container>
    )
}

export default ExclusiveFeatures;