import { styled } from '@mui/system';

import MapImage from '../../../assets/images/Contact-us-map.png';

const Image = styled('img')({
    width: '100%',
    marginTop: '20px',
    maxWidth: '1920px'
})

const Map = () => {
    return (
        <Image src={MapImage} alt='map' />
    )
}

export default Map;