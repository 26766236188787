import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';

import { H1, H2, H3, H4, H5, H6, P1, P2, P3, P4 } from '../../../utility/Typography';

const Card = styled(Box)(({theme})=>({
    padding: '10px',
    border: '2px solid #ffedbd',
    borderRadius: '0 20px 0 20px',

    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '100%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '45%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
        width: '45%'
    },
    [theme.breakpoints.up('xl')]: {
        width: '45%'
    }
}))

const SpecialtyCard = (props) => {
    return (
        <Card>
            <img src={props.image} alt='specialty_icon' />
            <P2 styled={{marginTop: '30px'}}>
                {props.title}
            </P2>
            <P3 style={{marginTop: '30px'}}>
                {props.text}
            </P3>
        </Card>
    )
}

export default SpecialtyCard;